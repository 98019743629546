import { ClienteUnidade } from "../types/cliente_unidade";

export const novoClienteUnidade = (): ClienteUnidade => {
  return {
    cliente_id: "",
    nome_referencia: "",
    cpf_cnpj: "",
    endereco: "",
    numero: "",
    cep: "",
    cidade: "",
    uf: "",
    ativo: true,
    silos: [],
    responsaveis: [],
    imagem: "",
    imagem_logo: "",
    latitude: "",
    longitude: "",
    body_center_map_image: "",
    body_center_map_points: [],
    body_center_map_position: {
      scale: 1,
      x: 0,
      y: 0
    },
    allowed_documents: {},
  };
};
