import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Login } from "../sistema/views/Login";
import { Dashboard } from "../leituras/views/Dashboard";
import { ClienteList } from "../sistema/views/ClienteList";
import { ClienteEdit } from "../sistema/views/ClienteEdit";
import { Logout } from "../sistema/views/Logout";
import { EquipamentoList } from "../sistema/views/EquipamentosList";
import { EquipamentoEdit } from "../sistema/views/EquipamentosEdit";
import { UsuarioList } from "../sistema/views/UsuarioList";
import { UsuarioEdit } from "../sistema/views/UsuarioEdit";
import { LeituraList } from "../leituras/views/LeiturasList";
import { LeituraEdit } from "../leituras/views/LeiturasEdit";
import Home from "../sistema/views/Home";
import { InsetosList } from "../sistema/views/InsetosList";
import { InsetosEdit } from "../sistema/views/InsetosEdit";
import { AvaliacaoInicialQuestaoList } from "../sistema/views/AvaliacaoInicialQuestaoList";
import { AvaliacaoInicialQuestaoEdit } from "../sistema/views/AvaliacaoInicialQuestaoEdit";
import { TipoFosfinaList } from "../sistema/views/TipoFosfinaList";
import { TipoFosfinaEdit } from "../sistema/views/TipoFosfinaEdit";
import { CaminhaoList } from "../sistema/views/CaminhaoList";
import { CaminhaoEdit } from "../sistema/views/CaminhaoEdit";
import { useChangeNetworkStatus } from "../hooks/useChangeNetworkStatus";
import { AvaliacaoInicialList } from "../leituras/views/AvaliacaoInicialList";
import { AvaliacaoInicialEdit } from "../leituras/views/AvaliacaoInicialEdit";
import { PreparacaoInicialList } from "../leituras/views/PreparacaoInicialList";
import { PreparacaoInicialEdit } from "../leituras/views/PreparacaoInicialEdit";
import { LoginExterno } from "../sistema/views/LoginExterno";
import { PaginaNaoEncontrada } from "../sistema/views/PaginaNaoEncontrada";
import useIsMobile from "../hooks/useIsMobile";
import { BodyCenterVisitList } from "../body-center/views/BodyCenterVisitList";
import { BodyCenterVisitEdit } from "../body-center/views/BodyCenterVisitEdit";
import { BodyCenterDashboard } from "../body-center/views/BodyCenterDashboard";
import { CompanyDocumentList } from "../company/views/CompanyDocumentList";
import { CompanyDocumentEdit } from "../company/views/CompanyDocumentEdit";

export const App: React.FC<{}> = () => {
  useChangeNetworkStatus();
  useIsMobile();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/clientes" element={<ClienteList />} />
        <Route path="/clientes/:id" element={<ClienteEdit />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/equipamentos" element={<EquipamentoList />} />
        <Route path="/equipamentos/:id" element={<EquipamentoEdit />} />
        <Route path="/usuarios" element={<UsuarioList />} />
        <Route path="/usuarios/:id" element={<UsuarioEdit />} />
        <Route path="/leituras" element={<LeituraList />} />
        <Route path="/leituras/:id" element={<LeituraEdit />} />
        <Route path="/insetos" element={<InsetosList />} />
        <Route path="/insetos/:id" element={<InsetosEdit />} />
        <Route path="/avaliacao-questoes" element={<AvaliacaoInicialQuestaoList />} />
        <Route path="/avaliacao-questoes/:id" element={<AvaliacaoInicialQuestaoEdit />} />
        <Route path="/tipo-fosfina" element={<TipoFosfinaList />} />
        <Route path="/tipo-fosfina/:id" element={<TipoFosfinaEdit />} />
        <Route path="/caminhao" element={<CaminhaoList />} />
        <Route path="/caminhao/:id" element={<CaminhaoEdit />} />
        <Route path="/avaliacoes-iniciais" element={<AvaliacaoInicialList />} />
        <Route path="/avaliacoes-iniciais/:id" element={<AvaliacaoInicialEdit />} />
        <Route path="/preparacoes-iniciais" element={<PreparacaoInicialList />} />
        <Route path="/preparacoes-iniciais/:id" element={<PreparacaoInicialEdit />} />
        <Route path="/body-center-visits" element={<BodyCenterVisitList />} />
        <Route path="/body-center-visits/:id" element={<BodyCenterVisitEdit />} />
        <Route path="/body-center-dashboard" element={<BodyCenterDashboard />} />
        <Route path="/body-center-dashboard/:client" element={<BodyCenterDashboard />} />
        <Route path="/login-externo" element={<LoginExterno />} />
        <Route path="/company-documents" element={<CompanyDocumentList />} />
        <Route path="/company-documents/:id" element={<CompanyDocumentEdit />} />
        <Route path="/*" element={<PaginaNaoEncontrada />} />
      </Routes>
    </BrowserRouter>
  )
}