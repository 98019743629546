import { store } from "../../app/mainReducer";
import { CompanyDocument } from "../../company/types/company_document";
import { ClienteUnidade } from "../../sistema/types/cliente_unidade";
import { TInitialState } from "../reducer";

export const getCommomDocuments = (state: TInitialState, documents: CompanyDocument[]) => {
  const units = (state.dashboard?.selectedUnits ?? []);

  if (units.length === 0 || !state.dashboard?.selectedClient) {
    state.currentDocuments = [];
    return;
  }

  const clientWithAcess = (state.dashboard?.clients ?? []).find(c => c.id == state.dashboard?.selectedClient?.id as any)

  const unitObject: ClienteUnidade[] = (clientWithAcess?.unidades ?? []).filter(u => (units ?? []).map((i) => Number(i)).includes(u.id as any));

  if (unitObject.length === 0) {
    state.currentDocuments = [];
    return;
  }
console.log("b", unitObject);
  const commonDocuments = Object.entries(unitObject[0].allowed_documents ?? {}).reduce((acc, [key, value]) => {
    if (value === true) {
      acc[Number(key)] = value;
    }
    return acc;
  }, {} as Record<number, boolean>);
  console.log("c");
  if (!commonDocuments) {
    state.currentDocuments = [];
    return;
  }
  console.log("d", commonDocuments);
  for (let i = 1; i < unitObject.length; i++) {
    const currentDocs = unitObject[i].allowed_documents ?? {};

    Object.keys(commonDocuments).forEach((docId) => {
      if (!currentDocs[Number(docId)]) {
        delete commonDocuments[Number(docId)];
      }
    });
  }
  console.log("e");
  const commomDocumentsObject = documents.map((document) => {
    return {
      ...document,
      items: document.items.filter((item) => {
        return commonDocuments[item.id as number] === true;
      })
    }
  }).filter((document) => document.items.length > 0);
  console.log(commomDocumentsObject, "f");
  return commomDocumentsObject;
}