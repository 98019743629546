import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Flex
} from "@chakra-ui/react";
import { FiBarChart2, FiMap, FiTrendingUp } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { ClienteUnidade } from "../../sistema/types/cliente_unidade";
import InputLocalMultiSearch from "../../sistema/components/InputLocalMultiSearch";
import { formatData } from "../../utils/data";
import { Form, Formik } from "formik";
import { FirstTab } from "./FirstTab";
import { SecondTab } from "./SecondTab";
import { ThirdTab } from "./ThirdTab";
import { useEffect, useState } from "react";
import { BodyCenterVisit } from "../types/body_center_visit";
import Select from 'react-select';
interface UnitDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  unit: ClienteUnidade;
}

export const UnitDetailsModal = ({ isOpen, onClose, unit }: UnitDetailsModalProps) => {
  const { t } = useTranslation();

  const [selectedVisits, setSelectedVisits] = useState<BodyCenterVisit[]>((unit.bodyCenterVisits ?? []));

  useEffect(() => {
    setSelectedVisits(unit.bodyCenterVisits ?? []);
  }, [unit]);

  const selectOptions = (unit.bodyCenterVisits ?? []).map((visit, index) => ({
    label: `${t('BodyCenterSingular')} ${formatData(visit.date, 'dd/MM/yyyy')} ${visit.service_number ? (' - OSC: ' + visit.service_number) : ''}`,
    value: `${visit.id}`
  }))

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl" scrollBehavior="inside">
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent margin={0} rounded="none" height="100vh" maxHeight="100vh">
        <ModalHeader
          bgGradient="linear(to-r, green.600, green.700)"
          color="white"
          className="py-2 md:py-3 px-2 md:px-8"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex direction={'column'}>
            <Box className="text-xl md:text-2xl" fontWeight="bold">
              {t('BodyCenter')}
            </Box>
            <Box className="text-sm md:text-base text-gray-200">
              {unit.nome_referencia}
            </Box>
          </Flex>
          <ModalCloseButton position="static" />
        </ModalHeader>

        <ModalBody p={0}>
          <Box className="px-2 md:px-6" py={2} zIndex={50}>
            <Select
              isMulti
              options={selectOptions}
              value={selectOptions.filter(option =>
                selectedVisits.map(v => `${v.id}`).includes(option.value)
              )}
              onChange={(selectedOptions) => {
                setSelectedVisits(selectedOptions ? selectedOptions.map(option => unit.bodyCenterVisits?.find(visit => visit.id == Number(option.value)) as BodyCenterVisit) : [])
              }}
              placeholder={t('SelectPlaceholder')}
              isClearable
              noOptionsMessage={() => t('NoOptionsMessage')}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999
                }),
                multiValue: (provided) => ({
                  ...provided,
                  fontSize: '0.8rem',
                  padding: '0px',
                }),
                multiValueLabel: (provided) => ({
                  ...provided,
                  padding: '0px',
                }),
                multiValueRemove: (provided) => ({
                  ...provided,
                  padding: '0px',
                })
              }}
            />
          </Box>

          <Tabs
            isFitted
            variant="unstyled"
            height="calc(100% - 60px)"
            width={["100%", "auto", "auto"]}
          >
            <TabList
              bg="white"
              borderBottom="1px"
              borderColor="gray.200"
              position="relative"
              zIndex={1}
              display={["flex", "flex", "flex"]}
              flexDirection={["column", "row", "row"]}
            >
              <Tab
                gap={2}
                className="pb-4 pt-4 md:pt-0"
                fontWeight="medium"
                color="gray.600"
                width={["100%", "auto", "auto"]}
                _selected={{
                  color: "green.600",
                  borderBottom: "3px solid",
                  borderColor: "green.600",
                  fontWeight: "bold",
                }}

              >
                <FiMap /> {t('BodyCenterMapaDeIscas')}
              </Tab>
              <Tab
                className="pb-4 pt-4 md:pt-0"
                gap={2}
                fontWeight="medium"
                color="gray.600"
                width={["100%", "auto", "auto"]}
                _selected={{
                  color: "green.600",
                  borderBottom: "3px solid",
                  borderColor: "green.600",
                  fontWeight: "bold",
                }}

              >
                <FiBarChart2 /> {t('BodyCenterPerformanceLastVisit')}
              </Tab>
              <Tab
                className="pb-4 pt-4 md:pt-0"
                gap={2}
                fontWeight="medium"
                color="gray.600"
                width={["100%", "auto", "auto"]}
                _selected={{
                  color: "green.600",
                  borderBottom: "3px solid",
                  borderColor: "green.600",
                  fontWeight: "bold",
                }}

              >
                <FiTrendingUp /> {t('ConsumptionTrend')}
              </Tab>
            </TabList>

            <TabPanels flex={1} overflow="auto">
              <TabPanel h="100%" p={0}>
                <Box height="100%" className="max-w-7xl mx-auto p-2 md:p-2">
                  <FirstTab selectedVisits={selectedVisits} unit={unit} />
                </Box>
              </TabPanel>

              <TabPanel p={0}>
                <Box height="100%" className="max-w-7xl mx-auto py-4 px-2 md:px-8">
                  <SecondTab unit={unit} selectedVisits={selectedVisits} />
                </Box>
              </TabPanel>

              <TabPanel p={0}>
                <Box height="100%" className="max-w-7xl mx-auto py-4 px-2 md:px-8">
                  <ThirdTab unit={unit} selectedVisits={selectedVisits} />
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};