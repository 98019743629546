import { BodyCenterVisitOccurrence } from "../types/body_center_visit_occurrence";
import { getBaitsTypes } from "./bodyCenterBaits";

export const isConsumed = (occurrence: BodyCenterVisitOccurrence) => {
  if (occurrence.bait_type == 0) {
    return false;
  }
  const ocurrences = (getBaitsTypes().find(baitType => baitType.value == occurrence.bait_type)?.occurrences ?? []);
  if (!ocurrences.length) {
    return false;
  }
  return ocurrences.find(o => o.value == occurrence.bait_type_occurrence)?.isConsumed == 1;
}

export const isIgnored = (occurrence: BodyCenterVisitOccurrence) => {
  if (occurrence.bait_type == 0) {
    return true;
  }

  const ocurrences = (getBaitsTypes().find(baitType => baitType.value == occurrence.bait_type)?.occurrences ?? []);
  if (!ocurrences.length) {
    return false;
  }
  return ocurrences.find(o => o.value == occurrence.bait_type_occurrence)?.isIgnored == 1;
}

export const isInterrupt = (occurrence: BodyCenterVisitOccurrence) => {
  if (occurrence.bait_type == 0) {
    return false;
  }

  const ocurrences = (getBaitsTypes().find(baitType => baitType.value == occurrence.bait_type)?.occurrences ?? []);
  if (!ocurrences.length) {
    return false;
  }
  return ocurrences.find(o => o.value == occurrence.bait_type_occurrence)?.isIgnored == 0 && ocurrences.find(o => o.value == occurrence.bait_type_occurrence)?.isConsumed == 0;
}