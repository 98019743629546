import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { ListWithFiltersPayload } from "../sistema/types/listPayload";
import { mensagemErro, mensagemSucesso } from "../utils/toasts";
import { CompanyDocument } from "./types/company_document";

type TInitialState = {
  isLoading?: boolean;
  error?: string;
  success?: string;
  documents?: ApiPaginationRes<CompanyDocument[]>;
  document?: CompanyDocument | null;
  activeDocuments?: CompanyDocument[];
};

const initialState: TInitialState = {};

const reducer = createSlice({
  name: "company",
  initialState,
  reducers: {
    // List documents
    documentsRequest(state, _: PayloadAction<ListWithFiltersPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    documentsRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<CompanyDocument[]>>
    ) {
      state.isLoading = false;
      state.documents = payload;
    },

    // Single document
    documentRequest(state, _: PayloadAction<string>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.document = null;
    },
    documentRequestSuccess(state, { payload }: PayloadAction<CompanyDocument>) {
      state.isLoading = false;
      state.document = payload;
    },

    // Save document
    saveDocumentRequest(state, _: PayloadAction<CompanyDocument>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveDocumentRequestSuccess(state, { payload }: PayloadAction<CompanyDocument>) {
      state.isLoading = false;
      state.document = payload;
      state.success = "Sucesso";
      mensagemSucesso("Sucesso");
    },

    // Delete document
    deleteDocumentRequest(state, _: PayloadAction<{ id: string; list: ListWithFiltersPayload }>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteDocumentRequestSuccess(state) {
      state.isLoading = false;
      state.success = "Sucesso";
      mensagemSucesso("Sucesso");
    },

    activeDocumentsRequest(state) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    activeDocumentsRequestSuccess(
      state,
      { payload }: PayloadAction<CompanyDocument[]>
    ) {
      state.isLoading = false;
      state.activeDocuments = payload;
    },

    // Error handling
    error(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
      mensagemErro(payload);
    },
  },
});

export const companyActions = reducer.actions;
export default reducer;
