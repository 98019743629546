import { useIsAuth } from "../../hooks/useIsAuth"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { FiTrash } from "react-icons/fi";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import { Pagination } from "../../sistema/components/Pagination";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import { formatData } from "../../utils/data";
import { useTranslation } from "react-i18next";
import { Badge } from "@chakra-ui/react";
import { BodyCenterVisit } from "../types/body_center_visit";
import Accordion from "../../sistema/components/Accordion";
import { ListFilters } from "../../sistema/components/ListFilters";
import InputField from "../../sistema/components/InputField";
import { sistemaActions } from "../../sistema/recucer";
import InputSelect from "../../sistema/components/InputSelect";
import { ListWithFiltersPayloadFilter } from "../../sistema/types/listPayload";
import { bodyCenterActions } from "../reducer";

export const BodyCenterVisitList = () => {
  useIsAuth();
  const dispatch = useDispatch()
  const eDesenvolvedor = useSelector((state: RootState) => (state.sistema.user as any)?.e_desenvolvedor == 1);
  const [page, setPage] = useState(1);
  const success = useSelector((state: RootState) => state.bodyCenter.success);

  useEffect(() => {
    if (success === 'Registro deletado com sucesso.') {
      onSearchData();
    }
  }, [success])

  const bodyCenters = useSelector((state: RootState) => state.bodyCenter);

  const { t } = useTranslation()

  const headers: TableHeaders<BodyCenterVisit>[] = [
    {
      label: t('UserLoginsData'),
      wrapped: true,
      render: (item) => <React.Fragment>
        {formatData(item.date, 'dd/MM/yyyy')} {item.service_number ? (' - OSC: ' + item.service_number) : ''} <br />
        <div className="flex flex-col sm:flex-row gap-1">
          <Badge colorScheme="cyan">
            {t('CreatedAt')} {formatData(item.created_at as string, 'dd/MM/yyyy HH:mm')}
          </Badge>
          <Badge colorScheme="purple">
            {t('UpdatedAt')} {formatData(item.updated_at as string, 'dd/MM/yyyy HH:mm')}
          </Badge>
        </div>
      </React.Fragment>,
    },
    {
      label: t('UsuarioCliente'),
      wrapped: false,
      render: (item) => <React.Fragment>
        {item.clientUnit?.nome_referencia} - {item.clientUnit?.cidade} <br />
        {item.client?.nome}
      </React.Fragment>,
    },
    {
      label: '',
      wrapped: false,
      render: (item) => <div className="flex flex-col sm:flex-row gap-1">
        <Badge colorScheme="facebook">
          {t('BodyCenterOccurrences')} ({item?.occurrences_count ?? 0})
        </Badge>
        <Badge colorScheme="orange">
           {t('BodyCenterNonconformities')} ({(item.nonconformities ?? []).reduce((acc, i) => acc + parseInt(i.quantity as any ?? '0'), 0)})
        </Badge>
        <Badge colorScheme="purple">
           {t('BodyCenterSightings')} ({(item.sightings ?? []).reduce((acc, i) => acc + parseInt(i.quantity_alive as any ?? '0') + parseInt(i.quantity_death as any ?? '0'), 0)})
        </Badge>
        <Badge colorScheme="gray">
           {t('BodyCenterTraces')} ({(item.traces ?? []).reduce((acc, i) => acc + parseInt(i.quantity as any ?? '0'), 0)})
        </Badge>
      </div>,
    },
    {
      label: t('SistemaAções'),
      wrapped: false,
      render: (item: any, index: any) => <>
        {eDesenvolvedor && <ConfirmButton confirm={() => onDeleteItem(item)}><FiTrash /></ConfirmButton>}
      </>,
      notClicable: true,
    },
  ];

  const onDeleteItem = (visit: BodyCenterVisit) => {
    dispatch(bodyCenterActions.deleteVisitRequest(visit));
  }

  const navigate = useNavigate();

  const todosClientes = useSelector((state: RootState) => state.sistema.todosClientes || []);

  const [filters, setFilters] = useState<ListWithFiltersPayloadFilter>({})

  const onSearchData = () => {
    dispatch(bodyCenterActions.visitsRequest({ filters, page }))
  }

  useEffect(() => {
    dispatch(sistemaActions.todosClientesRequest())
  }, [dispatch])

  useEffect(() => {
    onSearchData();
  }, [dispatch, page])

  return (
    <Layout>
      <ListHeader label={t('BodyCenterPlural')}>
        <DefaultButton ml={4} to="/body-center-visits/new"> {t('SistemaNova')} {t('BodyCenterSingular')} </DefaultButton>
      </ListHeader>

      <Loader isLoading={bodyCenters?.isLoading ?? false} />

      <ListFilters onSubmit={(values) => {
        setFilters(values);
        dispatch(bodyCenterActions.visitsRequest({ filters: values, page: 1 }))
      }} form={(values) => (
        <div className="w-full grid grid-cols-1 md:grid-cols-5 gap-2">
          <InputField type="text" label={t('BodyCenterServiceNumber')} name="osc" />
          <InputField type="date" label={t('SistemaDataInicial')} name="date_start" />
          <InputField type="date" label={t('SistemaDataFinal')} name="date_end" />
          <InputSelect label={t('UsuarioCliente')} name="client_id">
            {todosClientes && todosClientes.map((i) => (
              <option value={i.id} key={i.id}>{i.nome}</option>
            ))}
          </InputSelect>

          <InputSelect label={t('DashboardUnidade')} name="client_unit_id">
            {todosClientes && (todosClientes.find((i: any) => i.id == values.client_id)?.unidades ?? []).map((i) => (
              <option value={i.id} key={i.id}>{i.nome_referencia}</option>
            ))}
          </InputSelect>

        </div>
      )} />

      {bodyCenters && <ResponsiveTable onClick={(item: any) => navigate(`/body-center-visits/${item.item.id}`)} headers={headers} data={bodyCenters?.visits?.data ?? []} />}

      {bodyCenters?.visits && <Pagination info={bodyCenters?.visits} paginate={setPage} />}
    </Layout>
  )
}
