import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/mainReducer';
import { companyActions } from '../reducer';
import {
  VStack,
  Text,
  Icon,
  Button,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiChevronRight, FiFolder, FiFolderPlus } from 'react-icons/fi';
import { CompanyDocument } from '../types/company_document';
import { DocumentsModal } from './DocumentsModal';

type DocumentsProps = {
  documents: CompanyDocument[];
  selectionMode?: boolean;
  selectedDocuments?: Record<string, boolean>;
  onSelectionChange?: (documentId: string, selected: boolean) => void;
}

export const Documents = ({ 
  documents, 
  selectionMode = false,
  selectedDocuments = {},
  onSelectionChange
}: DocumentsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isLoading = useSelector((state: RootState) => state.company?.isLoading);

  const bgHover = useColorModeValue('gray.50', 'gray.700');
  const accentColor = useColorModeValue('blue.500', 'blue.300');

  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string | null>(null);

  useEffect(() => {
    dispatch(companyActions.documentsRequest({ page: 1, filters: { active: 1 } }));
  }, [dispatch]);

  const organizedDocuments = useMemo(() => {
    const grouped: Record<string, {
      subcategories: Record<string, {
        operators: Record<string, CompanyDocument[]>,
        documents: CompanyDocument[]
      }>,
      operators: Record<string, CompanyDocument[]>,
      documents: CompanyDocument[]
    }> = {};

    documents.forEach(doc => {
      const category = doc.category || 'Uncategorized';
      const subcategory = doc.subcategory;
      const operator = doc.user?.name;

      if (!grouped[category]) {
        grouped[category] = {
          subcategories: {},
          operators: {},
          documents: []
        };
      }

      if (subcategory) {
        if (!grouped[category].subcategories[subcategory]) {
          grouped[category].subcategories[subcategory] = {
            operators: {},
            documents: []
          };
        }

        if (operator) {
          if (!grouped[category].subcategories[subcategory].operators[operator]) {
            grouped[category].subcategories[subcategory].operators[operator] = [];
          }
          grouped[category].subcategories[subcategory].operators[operator].push(doc);
        } else {
          grouped[category].subcategories[subcategory].documents.push(doc);
        }
      } else {
        if (operator) {
          if (!grouped[category].operators[operator]) {
            grouped[category].operators[operator] = [];
          }
          grouped[category].operators[operator].push(doc);
        } else {
          grouped[category].documents.push(doc);
        }
      }
    });

    return grouped;
  }, [documents]);

  const renderCategory = (category: string, data: any) => {
    const hasSubcategories = Object.keys(data.subcategories).length > 0;
    const isSelected = selectedCategory === category;

    return (
      <VStack align="stretch" spacing={0} w="full">
        <Button
          variant="ghost"
          justifyContent="flex-start"
          px={2}
          py={1.5}
          onClick={() => {
            if (hasSubcategories) {
              setSelectedCategory(isSelected ? null : category);
              setSelectedSubcategory(null);
            } else {
              onOpen();
              setSelectedCategory(category);
              setSelectedSubcategory(null);
            }
          }}
          _hover={{ bg: bgHover }}
          leftIcon={<Icon as={hasSubcategories ? FiFolderPlus : FiFolder} color={accentColor} />}
          rightIcon={hasSubcategories ? 
            <Icon 
              as={FiChevronRight} 
              transform={isSelected ? 'rotate(90deg)' : undefined}
              transition="transform 0.2s"
            /> : undefined
          }
        >
          <Text fontSize="sm" fontWeight="medium">
            {category}
          </Text>
        </Button>

        {isSelected && hasSubcategories && (
          <VStack align="stretch" pl={6} spacing={0}>
            {Object.entries(data.subcategories).map(([subcat, subData]: [string, any]) => (
              <Button
                key={`${category}${subcat}`}
                variant="ghost"
                justifyContent="flex-start"
                px={2}
                py={1.5}
                onClick={() => {
                  onOpen();
                  setSelectedCategory(category);
                  setSelectedSubcategory(subcat);
                }}
                _hover={{ bg: bgHover }}
                leftIcon={<Icon as={FiFolder} color={accentColor} />}
              >
                <Text fontSize="sm">
                  {subcat}
                </Text>
              </Button>
            ))}
          </VStack>
        )}
      </VStack>
    );
  };

  const handleNavigate = (category: string | null, subcategory: string | null) => {
    setSelectedCategory(category);
    setSelectedSubcategory(subcategory);
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <VStack align="stretch" spacing={1} w="full">
        {Object.entries(organizedDocuments).map(([category, data]) => (
          renderCategory(category, data)
        ))}
      </VStack>

      <DocumentsModal
        isOpen={isOpen}
        onClose={onClose}
        documents={documents}
        selectedCategory={selectedCategory}
        selectedSubcategory={selectedSubcategory}
        onNavigate={handleNavigate}
        selectionMode={selectionMode}
        selectedDocuments={selectedDocuments}
        onSelectionChange={onSelectionChange}
      />
    </>
  );
};