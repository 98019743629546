import { useIsAuth } from "../../hooks/useIsAuth"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { FiTrash, FiFile } from "react-icons/fi";
import ResponsiveTable, { TableHeaders } from "../../sistema/components/ResponsiveTable";
import Layout from "../../sistema/components/Layout";
import { ListHeader } from "../../sistema/components/ListHeader";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import { Pagination } from "../../sistema/components/Pagination";
import { ConfirmButton } from "../../sistema/components/ConfirmButton";
import { formatData, getCurrentData } from "../../utils/data";
import { useTranslation } from "react-i18next";
import { Badge, Tooltip } from "@chakra-ui/react";
import { ListFilters } from "../../sistema/components/ListFilters";
import InputField from "../../sistema/components/InputField";
import InputSelect from "../../sistema/components/InputSelect";
import { ListWithFiltersPayloadFilter } from "../../sistema/types/listPayload";
import { companyActions } from "../reducer";
import { CompanyDocument } from "../types/company_document";

export const CompanyDocumentList = () => {
  useIsAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<ListWithFiltersPayloadFilter>({});

  const isLoading = useSelector((state: RootState) => state.company?.isLoading);
  const documents = useSelector((state: RootState) => state.company?.documents);
  const eDesenvolvedor = useSelector((state: RootState) => (state.sistema.user as any)?.e_desenvolvedor == 1);

  const headers: TableHeaders<CompanyDocument>[] = [
    {
      label: t('Document'),
      wrapped: true,
      render: (item) => (
        <div className="flex flex-col gap-1">
          <div className="font-semibold">
            {item.category} {item.subcategory && <React.Fragment>/ {item.subcategory ?? 'N/D'} </React.Fragment>}
          </div>
          {item?.user && <div>
            Operador: {item.user?.name}
          </div>}
          <div className="flex gap-2">
            <Badge colorScheme={item.active ? "blue" : "red"}>
              {item.active ? t('Active') : t('Inactive')}
            </Badge>
            <Badge colorScheme="gray">
              {item.items.length} {t('Items')}
            </Badge>
          </div>
        </div>
      ),
    },
    {
      label: t('Items'),
      wrapped: false,
      render: (item) => (
        <div className="flex flex-col gap-2">
          {item.items.slice(0, 3).map((doc) => (
            <div className="flex items-center gap-2">
              <span className="text-sm truncate">{doc.name}</span>
              <Badge colorScheme={new Date(formatData(doc.due_date, 'yyyy-MM-dd')) < new Date(formatData(getCurrentData(), 'yyyy-MM-dd')) ? "red" : "blue"}>
                {formatData(doc.due_date, 'dd/MM/yyyy')}
              </Badge>
            </div>
          ))}
          {item.items.length > 3 && (
            <Badge colorScheme="blue">
              +{item.items.length - 3} {t('more')}
            </Badge>
          )}
        </div>
      ),
    },
    {
      label: t('Actions'),
      wrapped: false,
      render: (item) => (
        <div className="flex gap-2">
          {eDesenvolvedor && (
            <ConfirmButton
              confirm={() => dispatch(companyActions.deleteDocumentRequest({
                id: item.id as any as string,
                list: {
                  filters,
                  page,
                }
              }))}
            >
              <FiTrash />
            </ConfirmButton>
          )}
        </div>
      ),
      notClicable: true,
    },
  ];

  const onSearchData = () => {
    dispatch(companyActions.documentsRequest({ filters, page }));
  };

  useEffect(() => {
    onSearchData();
    dispatch(companyActions.activeDocumentsRequest());
  }, [page]);

  const activeDocuments = useSelector((state: RootState) => state.company?.activeDocuments ?? []);

  const getSubcategoriesOfCategory = (category: string) => {
    return Array.from(new Set(activeDocuments.filter((o) => o.category == category && !!o.subcategory).map((o) => o.subcategory)));
  }

  return (
    <Layout>
      <ListHeader label={t('CompanyDocuments')}>
        <DefaultButton ml={4} to="/company-documents/novo">
          {t('NewDocument')}
        </DefaultButton>
      </ListHeader>

      <Loader isLoading={isLoading ?? false} />

      <ListFilters
        onSubmit={(values) => {
          setFilters(values);
          setPage(1);
          dispatch(companyActions.documentsRequest({ filters: values, page: 1 }));
        }}
        form={() => (
          <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-4">
            <small className="md:col-span-4">
              <b>{t('Já cadastrados')}:</b> {Array.from(new Set(activeDocuments.map((i) => `${i.category}${getSubcategoriesOfCategory(i.category).length ? ` (${getSubcategoriesOfCategory(i.category).join(', ')})` : ''
                }`))).join(', ')}
            </small>

            <InputField
              type="text"
              label={t('Category')}
              name="category"
            />
            <InputField
              type="text"
              label={t('Subcategory')}
              name="subcategory"
            />
            <InputSelect label={t('Status')} name="active">
              <option value="">{t('All')}</option>
              <option value="1">{t('Active')}</option>
              <option value="0">{t('Inactive')}</option>
            </InputSelect>
            <InputField
              type="text"
              label={t('DocumentName')}
              name="item_name"
            />
            <InputField
              type="date"
              label={t('DueDateStart')}
              name="item_due_date_start"
            />
            <InputField
              type="date"
              label={t('DueDateEnd')}
              name="item_due_date_end"
            />
            <InputField
              type="text"
              label={t('Technical')}
              name="item_technical"
            />
          </div>
        )}
      />

      <ResponsiveTable
        onClick={(item) => navigate(`/company-documents/${item.item.id}`)}
        headers={headers}
        data={documents?.data ?? []}
      />

      {documents && <Pagination info={documents} paginate={setPage} />}
    </Layout>
  );
};
