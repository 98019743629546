import { Button, Flex, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { useField } from "formik";
import { FiCamera, FiCheck, FiX } from "react-icons/fi";

interface IProps {
  label: string;
  name: string;
  mb?: string;
}

interface Event<T = EventTarget> {
  target: T;
}

const InputFile = ({ label, mb, ...props }: IProps) => {
  const [, { error, touched, value }, { setValue }] = useField(props);

  const randomId = Math.floor(Math.random() * 100).toString();

  const open = () => {
    document.getElementById(`image-field${randomId}`)?.click();
  }

  const ajustImage = (event: Event<HTMLInputElement>) => {
    let fileList = event.target.files;
    if (!fileList?.length) {
      return;
    }
    let file = fileList[0];

    let reader = new FileReader();
    reader.onloadend = () => {
      setValue(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const abreUrl = () => {
    const link = document.createElement('a');
    link.href = value;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Flex>
      <FormControl p={1} mb={mb ? mb : 0} isInvalid={touched && error ? true : false}>
        <Button size="sm" mr={1} borderWidth={error && '2px'} borderColor="red" colorScheme="teal" onClick={open}><FiCamera /> &nbsp; {label}</Button>
        {value && value.includes('http') && <Button mr={1} size="sm" colorScheme="teal" onClick={abreUrl}><FiCheck /> &nbsp; Abrir</Button>}
        {value && <Button size="sm" colorScheme="red" onClick={() => setValue('')}><FiX /> </Button>}
        <input type="file" onChange={ajustImage} style={{ display: 'none' }} id={`image-field${randomId}`} />
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    </Flex>
  )
}

export default InputFile;