import { Flex, Icon, Select, Table, Text, Td, Tr, Tbody, Heading, TabPanels, Badge } from "@chakra-ui/react";
import { Tab, TabList, Tabs } from "@chakra-ui/react";
import { CircularProgressLabel, CircularProgress, Box } from "@chakra-ui/react";
import { Grid } from "@chakra-ui/react";
import { TabPanel } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ClienteUnidade } from "../../sistema/types/cliente_unidade";
import { BodyCenterVisit } from "../types/body_center_visit";
import { formatData } from "../../utils/data";
import { FiCalendar, FiEye, FiFileText, FiSearch } from "react-icons/fi";
import { formatValor } from "../../utils/formatValor";
import { getBaitsTypes } from "../utils/bodyCenterBaits";
import { BodyCenterVisitOccurrence } from "../types/body_center_visit_occurrence";
import { NotFound } from "./NotFound";
import { BodyCenterVisitNonconformityFile } from "../types/body_center_visit_nonconformity_file";
import { NoVisitsAlert } from "./NoVisitsAlert";
import { isConsumed, isIgnored, isInterrupt } from "../utils/occurrenceUtils";

type SecondTabProps = {
  unit: ClienteUnidade;
  selectedVisits: BodyCenterVisit[];
};

export const SecondTab = ({ unit, selectedVisits }: SecondTabProps) => {
  const { t } = useTranslation();

  const [selectedVisit, setSelectedVisit] = useState<BodyCenterVisit | null>(null);

  useEffect(() => {
    setSelectedVisit(selectedVisits[0]);
  }, [selectedVisits]);

  const getQuantityPoints = () => {
    return unit.body_center_map_points.filter((p) => !p.src).length;
  }

  const getTotalVerificated = () => {
    return (selectedVisit?.occurrences ?? []).filter((p) => p.bait_type > 0 && p.bait_type_occurrence > 0).length ?? 0;
  }

  const getOccurrenceName = (occurrence: BodyCenterVisitOccurrence) => {
    const ocurrences = (getBaitsTypes().find(baitType => baitType.value == occurrence.bait_type)?.occurrences ?? []);
    if (!ocurrences.length) {
      return '';
    }
    return ocurrences.find(o => o.value == occurrence.bait_type_occurrence)?.name as string;
  }

  const getTotalConsumed = () => {
    return (selectedVisit?.occurrences ?? []).filter((p) => p.bait_type > 0 && p.bait_type_occurrence > 0).filter((p) => isConsumed(p)).length ?? 0;
  }

  const getNonConformities = () => {
    let reports: BodyCenterVisitNonconformityFile[] = [];

    (selectedVisit?.nonconformities ?? []).forEach(nc => {
      (nc.files ?? []).forEach(f => {
        reports.push(f);
      });
    });

    return {
      quantity: (selectedVisit?.nonconformities ?? []).reduce((acc, curr) => acc + curr.quantity, 0),
      reports,
    };
  }

  if (!selectedVisit) {
    return <NoVisitsAlert />
  }

  return (
    <React.Fragment>
      <Flex className="w-full md:max-w-[300px] mx-auto justify-center" mb={4}>
        <Select
          className="w-full"
          value={selectedVisit?.id}
          onChange={(e) => setSelectedVisit(selectedVisits.find(v => v.id == Number(e.target.value)) ?? null)}
        >
          {selectedVisits.map(v => (
            <option key={v.id} value={v.id}>{formatData(v.date, 'dd/MM/yyyy')} {v.service_number ? (' - OSC: ' + v.service_number) : ''}</option>
          ))}
        </Select>
      </Flex>
      <Grid templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]} gap={8} mb={4}>
        <Box
          bg="white"
          p={8}
          rounded="xl"
          shadow="sm"
          borderWidth={1}
          borderColor="gray.200"
          display="flex"
          flexDirection={["column", "row", "row"]}
          alignItems="center"
          gap={6}
        >

          <CircularProgress mx={'auto'} value={getTotalVerificated() / getQuantityPoints() * 100} size="140px" color="green.500" thickness="16px">
            <CircularProgressLabel fontSize="xl" fontWeight="bold" color="gray.700">
              {formatValor(getTotalVerificated() / getQuantityPoints() * 100)}%
            </CircularProgressLabel>
          </CircularProgress>

          <Box className="text-center md:text-left">
            <Box fontSize="2xl" fontWeight="bold" color="gray.700">
              {t('BodyCenterVerificationOfPoints')}
            </Box>
            <Box color="gray.500" mt={2}>
              {t('BodyCenterVerificationOfPointsDescription', { value: getTotalVerificated(), total: getQuantityPoints() })}
            </Box>
          </Box>

        </Box>
        <Box
          bg="white"
          p={8}
          rounded="xl"
          shadow="sm"
          borderWidth={1}
          borderColor="gray.200"
          display="flex"
          flexDirection={["column", "row", "row"]}
          alignItems="center"
          gap={6}
        >
          <CircularProgress value={getTotalConsumed() / getQuantityPoints() * 100} size="140px" color="green.500" thickness="16px">
            <CircularProgressLabel fontSize="xl" fontWeight="bold" color="gray.700">
              {formatValor(getTotalConsumed() / getQuantityPoints() * 100)}%
            </CircularProgressLabel>
          </CircularProgress>
          <Box className="text-center md:text-left">
            <Box fontSize="2xl" fontWeight="bold" color="gray.700">
              {t('BodyCenterBaitConsumption')}
            </Box>
            <Box color="gray.500" mt={2}>
              {t('BodyCenterBaitConsumptionDescription', { value: getTotalConsumed(), total: getQuantityPoints() })}
            </Box>
          </Box>
        </Box>
      </Grid>

      <Tabs variant="soft-rounded" colorScheme="green">
        <TabList mb={4} gap={4} display={["flex", "flex", "flex"]} flexDirection={["column", "row", "row"]} width={["100%", "auto", "auto"]}>
          <Tab
            bg="gray.100"
            _selected={{
              bg: "green.500",
              color: "white",
            }}
            width={["100%", "auto", "auto"]}
          >
            {t('BodyCenterSecondTabSightingsAndTraces')}
          </Tab>
          <Tab
            bg="gray.100"
            _selected={{
              bg: "green.500",
              color: "white",
            }}
            width={["100%", "auto", "auto"]}
          >
            {t('BodyCenterSecondTabNonConformitiesWithReports')}
          </Tab>
          <Tab
            bg="gray.100"
            _selected={{
              bg: "green.500",
              color: "white",
            }}
            width={["100%", "auto", "auto"]}
          >
            {t('BodyCenterSecondTabPointXConsume')}
          </Tab>
          <Tab
            bg="gray.100"
            _selected={{
              bg: "green.500",
              color: "white",
            }}
            width={["100%", "auto", "auto"]}
          >
            {t('BodyCenterObservation')}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            <Grid templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]} gap={8}>
              <Box
                bg="white"
                p={6}
                rounded="xl"
                shadow="sm"
                borderWidth={1}
                borderColor="gray.200"
              >
                <Box>
                  <div className="flex items-center gap-2">
                    <div className="p-2 bg-gray-100 rounded-lg">
                      <FiSearch className="w-5 h-5 text-gray-600" />
                    </div>
                    <div className="flex items-center gap-2">
                      <p className="text-2xl font-bold">
                        {t('BodyCenterTraces')}
                        <span className="ml-2 px-3 py-1 text-sm font-semibold text-gray-700 bg-gray-100 rounded-full">
                          {(selectedVisit?.traces ?? []).reduce((acc, curr) => acc + curr.quantity, 0)}
                        </span>
                      </p>
                    </div>
                  </div>
                  {(selectedVisit?.traces ?? []).length ? (
                    <Table variant="simple">
                      <Tbody>
                        {(selectedVisit?.traces ?? []).map((o) => (
                          <Tr key={o.id}>
                            <Td borderBottom="1px" borderColor="gray.200" py={2}>
                              <Flex align="center">
                                <Box>
                                  <Text fontWeight="medium" color="gray.700">
                                    {o.sector}
                                  </Text>
                                </Box>
                              </Flex>
                            </Td>
                            <Td borderBottom="1px" borderColor="gray.200" isNumeric>
                              <Text fontSize="2xl" fontWeight="bold" color="green.500">
                                {o.quantity}
                              </Text>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  ) : (
                    <div className="pt-4">
                      <NotFound />
                    </div>
                  )}
                </Box>
              </Box>
              <Box
                bg="white"
                p={6}
                rounded="xl"
                shadow="sm"
                borderWidth={1}
                borderColor="gray.200"
              >
                <Box>
                  <div className="flex items-center gap-2">
                    <div className="p-2 bg-yellow-100 rounded-lg">
                      <FiEye className="w-5 h-5 text-yellow-600" />
                    </div>
                    <div>
                      <p className="text-2xl font-bold">
                        {t('BodyCenterSightings')}
                        <span className="ml-2 px-3 py-1 text-sm font-semibold text-yellow-700 bg-yellow-100 rounded-full">
                          {(selectedVisit?.sightings ?? []).reduce((acc, curr) => acc + curr.quantity_alive + curr.quantity_death, 0)}
                        </span>
                      </p>
                    </div>
                  </div>
                  {(selectedVisit?.sightings ?? []).length ? (
                    <Table variant="simple">
                      <Tbody>
                        {(selectedVisit?.sightings ?? []).map((o) => (
                          <Tr key={o.id}>
                            <Td borderBottom="1px" borderColor="gray.200" py={2}>
                              <Flex align="center">
                                <Box>
                                  <Text fontWeight="medium" color="gray.700">
                                    {o.sector}
                                  </Text>
                                </Box>
                              </Flex>
                            </Td>
                            <Td borderBottom="1px" borderColor="gray.200" isNumeric>
                              <Text fontSize="md" fontWeight="bold" color="green.500">
                                {t('BodyCenterAlive')}({o.quantity_alive})
                              </Text>
                              <Text fontSize="md" fontWeight="bold" color="red.500">
                                {t('BodyCenterDeath')}({o.quantity_death})
                              </Text>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  ) : (
                    <div className="pt-4">
                      <NotFound />
                    </div>
                  )}
                </Box>
              </Box>
            </Grid>
          </TabPanel>
          <TabPanel p={0}>
            <Box
              bg="white"
              p={6}
              rounded="xl"
              shadow="sm"
              borderWidth={1}
              borderColor="gray.200"
            >
              <div className="flex flex-col space-y-6">
                {/* Não conformidades */}
                <div className="bg-gradient-to-r from-red-50 to-red-100 rounded-lg px-6 py-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="text-xl font-bold text-red-700">
                        {t('BodyCenterTotalNonConformities')}
                      </h3>
                    </div>
                    <div className="h-12 w-12 bg-red-200 rounded-full flex items-center justify-center">
                      <span className="text-2xl font-bold text-red-700">
                        {getNonConformities().quantity}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                  <div className="p-4 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-800">
                      {t('BodyCenterReports')}
                    </h3>
                  </div>

                  <div className="divide-y divide-gray-200">
                    {(getNonConformities().reports ?? []).map((report, index) => (
                      <div key={index} className="p-4 hover:bg-gray-50 transition-colors">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-3">
                            <div className="flex-shrink-0">
                              <div className="h-10 w-10 bg-gray-100 rounded-full flex items-center justify-center">
                                <FiFileText className="h-5 w-5 text-gray-600" />
                              </div>
                            </div>
                            <div>
                              <p className="text-sm font-medium text-gray-900">
                                {report.file_name}
                              </p>
                            </div>
                          </div>
                          <a
                            className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-green-700 bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            href={report.file_url}
                            target="_blank"
                          >
                            {t('BodyCenterViewReport')}
                          </a>
                        </div>
                      </div>
                    ))}

                    {!(getNonConformities().reports ?? []).length && (
                      <div className="p-8 text-center text-gray-500">
                        {t('BodyCenterNotFound')}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Box>
          </TabPanel>
          <TabPanel p={0}>
            <Box
              bg="white"
              rounded="xl"
              shadow="sm"
              borderWidth={1}
              borderColor="gray.200"
            >
              <div className="flex flex-col rounded-xl">
                {(selectedVisit?.occurrences ?? []).filter((a) => a.bait_type != 0).map((occurrence, index) => (
                  <div key={index} className={`flex p-6 flex-wrap items-center justify-between py-3 border-b border-gray-200 ${index % 2 == 0 ? '' : 'bg-gray-100'}`}>
                    <div className="flex items-center gap-3 w-full sm:w-auto mb-2 sm:mb-0">
                      <Badge colorScheme="blue" className="min-w-[80px] text-center">
                        {t('DashboardPonto')} {occurrence.point}
                      </Badge>
                      <span className="text-sm text-gray-700">
                        {getBaitsTypes().find(bt => bt.value === occurrence.bait_type)?.name ? 
                          t(getBaitsTypes().find(bt => bt.value === occurrence.bait_type)?.name || '') : 
                          t('BodyCenterBaitTypeNonConfigured')
                        }
                      </span>
                    </div>
                    {occurrence.bait_type > 0 && occurrence.bait_type_occurrence > 0 && (
                      <Badge colorScheme={isConsumed(occurrence) ? "red" : isIgnored(occurrence) ? "yellow" : "green"} className="w-full sm:w-auto text-center">
                        {isConsumed(occurrence) ? t('BodyCenterConsumed') : ''}
                        {isIgnored(occurrence) ? t('BodyCenterNaoMonitoradoNaoContabilizado') : ''}
                        {isInterrupt(occurrence) ? t('BodyCenterNonConsumed') : ''}
                        {getOccurrenceName(occurrence) ? ` (${t(getOccurrenceName(occurrence))})` : ''}
                      </Badge>
                    )}
                  </div>
                ))}

                {!(selectedVisit?.occurrences ?? []).length && (
                  <div className="py-8 text-center text-gray-500">
                    {t('BodyCenterNotFound')}
                  </div>
                )}
              </div>
            </Box>
          </TabPanel>
          <TabPanel p={0}>
            <Box
              bg="white"
              rounded="xl"
              shadow="sm"
              borderWidth={1}
              borderColor="gray.200"
              p={4}
            >
              <textarea
                value={selectedVisit?.observation || ''}
                disabled
                placeholder={t('BodyCenterObservation')}
                rows={12}
                style={{
                  width: '100%',
                  borderRadius: '8px',
                  padding: '8px',
                  resize: 'vertical',
                }}
              />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </React.Fragment>
  )
};
