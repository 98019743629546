import { all, call, put, take, takeLatest } from "redux-saga/effects";
import { bodyCenterActions, BodyCenterDashboard } from "./reducer";
import { PayloadAction } from "@reduxjs/toolkit";
import { apiCall } from "../utils/apiCall";
import { AxiosResponse } from "axios";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { BodyCenterVisit } from "./types/body_center_visit";
import { formatError } from "../utils/formatError";
import { ListWithFiltersPayload } from "../sistema/types/listPayload";
import { newBodyCenterVisit } from "./data/body_center_visit";
import { getConfigurationOfPointsInMap } from "./utils/bodyCenterMap";
import { companyActions } from "../company/reducer";
import { CompanyDocument } from "../company/types/company_document";

function* getVisitsWorker({ payload }: PayloadAction<ListWithFiltersPayload>) {
  try {
    const res: AxiosResponse<ApiPaginationRes<BodyCenterVisit[]>> = yield call(
      apiCall,
      {
        url: `body-center-visit?filters=${JSON.stringify(payload.filters)}&page=${payload.page}`,
        method: "get",
      }
    );
    yield put(bodyCenterActions.visitsRequestSuccess(res.data));
  } catch (error: any) {
    yield put(bodyCenterActions.error(formatError(error)));
  }
}

function* getVisitWorker({ payload }: PayloadAction<number | string>) {
  try {
    if (payload == 'new') {
      yield put(bodyCenterActions.visitRequestSuccess(newBodyCenterVisit()));
      return;
    }

    const res: AxiosResponse<BodyCenterVisit> = yield call(
      apiCall,
      {
        url: `body-center-visit/${payload}?include=occurrences,nonconformities.files,sightings,traces,clientUnit`,
        method: "get",
      }
    );
    yield put(bodyCenterActions.visitRequestSuccess({
      ...res.data,
      occurrences: getConfigurationOfPointsInMap(res.data),
    }));
  } catch (error: any) {
    yield put(bodyCenterActions.error(formatError(error)));
  }
}

function* saveVisitWorker({ payload }: PayloadAction<BodyCenterVisit>) {
  try {
    const res: AxiosResponse<BodyCenterVisit> = yield call(
      apiCall,
      {
        url: `body-center-visit?include=occurrences,nonconformities.files,sightings,traces,clientUnit`,
        method: "post",
        data: {
          ...payload,
          changed: true,
          occurrences: (payload.occurrences ?? []).map((i) => ({
            ...i,
            changed: true,
          }))
        },
      }
    );
    yield put(bodyCenterActions.saveVisitRequestSuccess({
      ...res.data,
      occurrences: getConfigurationOfPointsInMap(res.data),
    }));
    yield put(bodyCenterActions.success("Registro salvo com sucesso."));
  } catch (error: any) {
    yield put(bodyCenterActions.error(formatError(error)));
  }
}

function* deleteVisitWorker({ payload }: PayloadAction<BodyCenterVisit>) {
  try {
    yield call(
      apiCall,
      {
        url: `body-center-visit/${payload.id}`,
        method: "delete",
      }
    );
    yield put(bodyCenterActions.deleteVisitRequestSuccess());
  } catch (error: any) {
    yield put(bodyCenterActions.error(formatError(error)));
  }
}

function* getBodyCenterDashboardRequestWorker({ payload }: PayloadAction<{ client: string, units: string[] }>) {
  try {
    const resCompanyActiveDocuments: AxiosResponse<CompanyDocument[]> = yield call(apiCall, {
      url: `company-documents-active`,
      method: "get",
    });
    yield put(companyActions.activeDocumentsRequestSuccess(resCompanyActiveDocuments.data));
    const res: AxiosResponse<BodyCenterDashboard> = yield call(
      apiCall,
      {
        url: `body-center-clients`,
        method: "get",
      }
    );
    yield put(bodyCenterActions.bodyCenterDashboardRequestSuccess({
      dashboard: res.data,
      client: payload.client,
      units: payload.units,
      activeDocuments: resCompanyActiveDocuments.data,
    }));
  } catch (error: any) {
    yield put(bodyCenterActions.error(formatError(error)));
  }
}

export function* bodyCenterSaga() {
  yield all([
    takeLatest(bodyCenterActions.visitsRequest.type, getVisitsWorker),
    takeLatest(bodyCenterActions.visitRequest.type, getVisitWorker),
    takeLatest(bodyCenterActions.saveVisitRequest.type, saveVisitWorker),
    takeLatest(bodyCenterActions.deleteVisitRequest.type, deleteVisitWorker),
    takeLatest(bodyCenterActions.bodyCenterDashboardRequest.type, getBodyCenterDashboardRequestWorker),
  ]);
}
