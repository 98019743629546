import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { mensagemErro, mensagemSucesso } from "../utils/toasts";
import { BodyCenterVisit } from "./types/body_center_visit";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { ListWithFiltersPayload } from "../sistema/types/listPayload";
import { Cliente } from "../sistema/types/cliente";
import { CompanyDocument } from "../company/types/company_document";
import { getCommomDocuments } from "./utils/commonDocuments";
import companySlice from "../company/reducer";

export type BodyCenterDashboard = {
  clients?: Cliente[];
  selectedClient?: Cliente;
  selectedUnits?: number[];
}

export type TInitialState = {
  error?: string;
  success?: string;
  isLoading?: boolean;
  visits?: ApiPaginationRes<BodyCenterVisit[]>;
  visit?: BodyCenterVisit;
  dashboard?: BodyCenterDashboard;
  currentDocuments?: CompanyDocument[];
};

const initialState: TInitialState = {};

const reducer = createSlice({
  name: "bodyCenter",
  initialState,
  reducers: {
    error(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
      state.success = "";
      mensagemErro(payload);
    },
    success(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = "";
      state.success = payload;
      mensagemSucesso(payload);
    },

    visitsRequest(state, _: PayloadAction<ListWithFiltersPayload>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    visitsRequestSuccess(
      state,
      { payload }: PayloadAction<ApiPaginationRes<BodyCenterVisit[]>>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.visits = payload;
    },

    visitRequest(state, _: PayloadAction<number | string>) {
      state.isLoading = true;
      state.visit = undefined;
      state.error = "";
      state.success = "";
    },
    visitRequestSuccess(
      state,
      { payload }: PayloadAction<BodyCenterVisit>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.visit = payload;
    },
    saveVisitRequest(state, _: PayloadAction<BodyCenterVisit>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    saveVisitRequestSuccess(
      state,
      { payload }: PayloadAction<BodyCenterVisit>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "";
      state.visit = payload;
    },
    deleteVisitRequest(state, _: PayloadAction<BodyCenterVisit>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
    },
    deleteVisitRequestSuccess(
      state,
      _: PayloadAction<void>
    ) {
      state.isLoading = false;
      state.error = "";
      state.success = "Registro deletado com sucesso.";
    },

    bodyCenterDashboardRequest(state, _: PayloadAction<{ client: string, units: string[] }>) {
      state.isLoading = true;
      state.error = "";
      state.success = "";
      state.dashboard = { clients: [], selectedClient: undefined };
    },
    
    bodyCenterDashboardRequestSuccess(
      state,
      { payload }: PayloadAction<{ dashboard: BodyCenterDashboard, client: string, units: string[]; activeDocuments: CompanyDocument[] }>
    ) {
      state.error = "";
      state.success = "";
      state.isLoading = false;

      if (payload?.dashboard?.clients?.length === 1) {
        state.dashboard = {
          ...payload.dashboard,
          selectedClient: payload.dashboard.clients[0],
          selectedUnits: payload.dashboard.clients[0].unidades.map(u => u.id as number),
        };
        state.currentDocuments = getCommomDocuments(state, payload.activeDocuments);
        return;
      }

      if (payload.client) {
        const clientWithAcess = (payload.dashboard.clients ?? []).find(c => c.id == payload.client as any)

        if (!clientWithAcess) {
          state.dashboard = {
            ...payload.dashboard,
            selectedClient: undefined,
            selectedUnits: [],
          };
          state.currentDocuments = getCommomDocuments(state, payload.activeDocuments);
          return;
        }
        state.dashboard = {
          ...payload.dashboard,
          selectedClient: clientWithAcess,
          selectedUnits: clientWithAcess.unidades.filter(u => (payload.units ?? []).map((i) => Number(i)).includes(u.id as any)).map(u => u.id as any)
        };
        state.currentDocuments = getCommomDocuments(state, payload.activeDocuments);
        return;
      }

      state.dashboard = {
        ...payload.dashboard,
        selectedClient: undefined,
        selectedUnits: [],
      };
      state.currentDocuments = getCommomDocuments(state, payload.activeDocuments);
    },
    bodyCenterDashboardSelectClient(state, { payload }: PayloadAction<{ client: Cliente | undefined; activeDocuments: CompanyDocument[] }>) {
      if (state.dashboard) {
        state.dashboard.selectedClient = payload.client;
        state.dashboard.selectedUnits = [];
        state.currentDocuments = getCommomDocuments(state, payload.activeDocuments);
      }
    },
    bodyCenterDashboardSelectClientUnits(state, { payload }: PayloadAction<{ units: number[]; activeDocuments: CompanyDocument[] }>) {
      if (state.dashboard) {
        state.dashboard.selectedUnits = payload.units;
        state.currentDocuments = getCommomDocuments(state, payload.activeDocuments);
      }
    },
  },
});

export const bodyCenterActions = reducer.actions;

export default reducer;
