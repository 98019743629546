import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  ModalFooter,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { CompanyDocument } from "../../company/types/company_document";
import { Documents } from "../../company/components/Documents";

interface DownloadCompanyDocumentsModalProps {
  isOpen: boolean;
  onClose: () => void;
  documents: CompanyDocument[];
}

export const DownloadCompanyDocumentsModal = ({
  isOpen,
  onClose,
  documents,
}: DownloadCompanyDocumentsModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="bg-green-50 text-green-800">
          {t("DownloadDocumentos")}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="py-6">
          <Documents documents={documents} />

          {documents.length === 0 && <>Nenhum encontrado</>}
        </ModalBody>
        <ModalFooter>
          <HStack justify="flex-end">
            <Button variant="ghost" onClick={onClose}>
              {t("Back")}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};