import { CompanyDocument } from "../types/company_document"

export const newDocument = (): CompanyDocument => {
  return {
    active: 1,
    category: '',
    subcategory: '',
    user_id: '',
    items: [],
  }
}