import React, { useMemo, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  HStack,
  Text,
  Icon,
  Button,
  Box,
  useColorModeValue,
  Tooltip,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Checkbox,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FiCalendar, FiDownload, FiUser, FiFolder, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { CompanyDocument } from '../types/company_document';
import { formatData } from '../../utils/data';
import { openLink } from '../../utils/openLink';

interface DocumentsModalProps {
  isOpen: boolean;
  onClose: () => void;
  documents: CompanyDocument[];
  selectedCategory: string | null;
  selectedSubcategory: string | null;
  onNavigate: (category: string | null, subcategory: string | null) => void;
  selectionMode?: boolean;
  selectedDocuments?: Record<string, boolean>;
  onSelectionChange?: (itemId: string, selected: boolean) => void;
}

export const DocumentsModal: React.FC<DocumentsModalProps> = ({
  isOpen,
  onClose,
  documents,
  selectedCategory,
  selectedSubcategory,
  onNavigate,
  selectionMode,
  selectedDocuments,
  onSelectionChange,
}) => {
  const { t } = useTranslation();
  const bgCard = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const colorMode = useColorModeValue('gray.50', 'gray.700');

  const organizedDocuments = useMemo(() => {
    const filtered = documents.filter(doc => 
      doc.category === selectedCategory && 
      (!selectedSubcategory || doc.subcategory === selectedSubcategory)
    );

    // Group by operator if exists
    const grouped: Record<string, CompanyDocument[]> = {};
    filtered.forEach(doc => {
      if (doc.user?.name) {
        if (!grouped[doc.user.name]) {
          grouped[doc.user.name] = [];
        }
        grouped[doc.user.name].push(doc);
      } else {
        if (!grouped['unassigned']) {
          grouped['unassigned'] = [];
        }
        grouped['unassigned'].push(doc);
      }
    });

    return {
      all: filtered,
      byOperator: grouped
    };
  }, [documents, selectedCategory, selectedSubcategory]);

  const hasOperators = Object.keys(organizedDocuments.byOperator).length > 1 || 
    (Object.keys(organizedDocuments.byOperator).length === 1 && !organizedDocuments.byOperator['unassigned']);

  const DocumentList = ({ docs }: { docs: CompanyDocument[] }) => {
    const allDocItemIds = useMemo(() => {
      return docs.flatMap(doc => doc.items.map(item => item.id));
    }, [docs]);

    const isAllSelected = useMemo(() => {
      return allDocItemIds.every(id => selectedDocuments?.[id as any]);
    }, [allDocItemIds, selectedDocuments]);

    const handleSelectAll = (checked: boolean) => {
      allDocItemIds.forEach(id => {
        onSelectionChange?.(id as any, checked);
      });
    };

    return (
      <VStack align="stretch" spacing={4} w="full">
        {docs.map((doc) => (
          <Box
            key={doc.id}
            bg={bgCard}
            borderWidth="1px"
            borderColor={borderColor}
            borderRadius="lg"
            p={4}
            transition="all 0.2s"
            _hover={{ borderColor: 'blue.400', shadow: 'md' }}
          >
            <VStack align="stretch">
              {selectionMode && (
                <Checkbox
                  isChecked={doc.items.every(item => selectedDocuments?.[item.id as any])}
                  isIndeterminate={
                    doc.items.some(item => selectedDocuments?.[item.id as any]) &&
                    !doc.items.every(item => selectedDocuments?.[item.id as any])
                  }
                  onChange={(e) => {
                    doc.items.forEach(item => {
                      onSelectionChange?.(item.id as any, e.target.checked);
                    });
                  }}
                />
              )}
              {(docs.length > 1 || doc.user) &&
              <VStack align="start">
                <Text fontWeight="bold">

                  {docs.length > 1 && (() => {
                    const firstDocCategory = docs[0].subcategory || docs[0].category;
                    const allSameCategory = docs.every(doc => 
                      (doc.subcategory || doc.category) === firstDocCategory
                    );
                    return !allSameCategory && (
                      <>{doc.subcategory || doc.category}</>
                    );
                  })()}
                  {doc.user && (
                    <HStack spacing={1}>
                      <Icon as={FiUser} />
                      <Text>{doc.user.name}</Text>
                    </HStack>
                  )}
                </Text>
              </VStack>}

              <VStack align="stretch" spacing={2}>
                {doc.items.map((item, idx) => (
                  <HStack
                    key={idx}
                    justify="space-between"
                    p={2}
                    bg={selectedDocuments?.[item.id as any] ? 'blue.50' : colorMode}
                    borderRadius="md"
                    borderWidth="1px"
                    borderColor={selectedDocuments?.[item.id as any] ? 'blue.200' : 'transparent'}
                    transition="all 0.2s"
                  >
                    <VStack align="start" spacing={1}>
                      <HStack spacing={2}>
                        {selectionMode && (
                          <Checkbox
                            isChecked={selectedDocuments?.[item.id as any] || false}
                            onChange={(e) => onSelectionChange?.(item.id as any, e.target.checked)}
                          />
                        )}
                        <Text fontWeight="medium">
                          {item.name}
                        </Text>
                      </HStack>
                      {item.due_date &&
                      <HStack spacing={2}>
                        <HStack spacing={1} color="gray.500">
                          <Icon as={FiCalendar} boxSize={3} />
                          <Text fontSize="xs">
                            {t('DueDate')} {formatData(item.due_date, 'dd/MM/yyyy')}
                          </Text>
                        </HStack>
                      </HStack>}
                    </VStack>

                    {item.file && item.file.includes('http') && (
                      <Tooltip label={t('Download')}>
                        <Button
                          size="sm"
                          colorScheme="blue"
                          onClick={() => openLink(item.file)}
                        >
                          <Icon as={FiDownload} />
                        </Button>
                      </Tooltip>
                    )}
                  </HStack>
                ))}
              </VStack>
            </VStack>
          </Box>
        ))}
      </VStack>
    );
  };

  const NavigationHeader = () => (
    <HStack spacing={2} mb={4}>
      <Button
        size="sm"
        variant="ghost"
        leftIcon={<Icon as={FiFolder} />}
        onClick={() => {
          if (selectedSubcategory) {
            onNavigate(selectedCategory, null);
          } else {
            onClose();
          }
        }}
      >
        {selectedCategory}
      </Button>
      {selectedSubcategory && (
        <>
          <Text color="gray.500">/</Text>
          <Button
            size="sm"
            variant="ghost"
            leftIcon={<Icon as={FiFolder} />}
            onClick={() => onNavigate(selectedCategory, selectedSubcategory)}
          >
            {selectedSubcategory}
          </Button>
        </>
      )}
    </HStack>
  );

  const ResponsiveTabList = ({ organizedDocuments, t }: any) => {
    const tabListRef = useRef(null);
    const isMobile = useBreakpointValue({ base: true, md: false });
    
    const scrollTabList = (direction: any) => {
      if (tabListRef.current) {
        const scrollAmount = direction === "left" ? -200 : 200;
        (tabListRef.current as any).scrollBy({ left: scrollAmount, behavior: "smooth" });
      }
    };
  
    // Ocultar botões de navegação se não houver scroll horizontal necessário
    const showNavButtons = Object.keys(organizedDocuments.byOperator).length > 
      (isMobile ? 1 : 3);
  
    return (
      <Flex position="relative" width="100%" mb={4}>
        {showNavButtons && (
          <Button 
            position="absolute" 
            left={-4} 
            top="50%" 
            transform="translateY(-50%)" 
            zIndex={2}
            size="sm" 
            rounded="full"
            colorScheme="blue"
            opacity={0.8}
            onClick={() => scrollTabList("left")}
          >
            <Icon as={FiChevronLeft} />
          </Button>
        )}
        
        <Box 
          width="100%" 
          overflow="hidden" 
          px={showNavButtons ? 8 : 0}
        >
          <TabList 
            ref={tabListRef}
            overflowX="auto"
            overflowY="hidden"
            whiteSpace="nowrap"
            css={{
              // Esconder a barra de rolagem, mas manter a funcionalidade
              "&::-webkit-scrollbar": { display: "none" },
              "scrollbarWidth": "none",
              "msOverflowStyle": "none"
            }}
          >
            <Tab 
              px={4} 
              py={2} 
              minWidth="auto" 
              fontWeight="medium"
            >
              {t('AllDocuments')}
            </Tab>
            
            {Object.keys(organizedDocuments.byOperator).sort().map(operator => (
              operator !== 'unassigned' && (
                <Tab 
                  key={operator} 
                  px={4} 
                  py={2} 
                  minWidth="auto"
                  fontWeight="medium"
                >
                  <HStack spacing={2}>
                    <Icon as={FiUser} />
                    <Text 
                      maxWidth={{ base: "80px", md: "120px", lg: "full" }}
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {operator}
                    </Text>
                  </HStack>
                </Tab>
              )
            ))}
          </TabList>
        </Box>
        
        {showNavButtons && (
          <Button 
            position="absolute" 
            right={-4} 
            top="50%" 
            transform="translateY(-50%)" 
            zIndex={2}
            size="sm" 
            rounded="full"
            colorScheme="blue"
            opacity={0.8}
            onClick={() => scrollTabList("right")}
          >
            <Icon as={FiChevronRight} />
          </Button>
        )}
      </Flex>
    );
  };

  const SortedDocumentList = ({ docs }: any) => {
    const sortedDocs = useMemo(() => {
      if (!docs || docs.length === 0) return [];

      return [...docs].sort((a, b) => {
        const nameA = (a.user?.name ?? '')?.toLowerCase() || "";
        const nameB = (b.user?.name ?? '')?.toLowerCase() || "";
        
        return nameA.localeCompare(nameB);
      });
    }, [docs]);

    return <DocumentList docs={sortedDocs} />;
  };

  const sortedOperators = useMemo(() => {
    const operators = Object.keys(organizedDocuments.byOperator)
      .filter(operator => operator !== 'unassigned');

    return operators.sort((a, b) => a.localeCompare(b));
  }, [organizedDocuments]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent maxW={{ base: "95%", lg: "800px" }}>
        <ModalHeader p={4}>
          <NavigationHeader />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {hasOperators ? (
            <Tabs variant="soft-rounded" colorScheme="blue">
              <ResponsiveTabList organizedDocuments={organizedDocuments} t={t} />
              <TabPanels>
                <TabPanel px={0}>
                  <SortedDocumentList docs={organizedDocuments.all} />
                </TabPanel>
                {sortedOperators.map(operator => (
                  <TabPanel key={operator} px={0}>
                    <DocumentList docs={organizedDocuments.byOperator[operator]} />
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          ) : (
            <DocumentList docs={organizedDocuments.all} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}; 