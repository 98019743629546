import React, { useEffect, useState } from "react";
import { ClienteUnidade } from "../types/cliente_unidade"
import { Button, ModalHeader, Text, ModalContent, ModalOverlay, Modal, useDisclosure, ModalCloseButton, ModalBody, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ListHeaderSecondary } from "./ListHeaderSecondary";
import { Form, useField, useFormikContext } from "formik";
import { Formik } from "formik";
import { RootState } from "../../app/mainReducer";
import { useSelector } from "react-redux";
import { Documents } from "../../company/components/Documents";

interface ClientUnitDocumentsPermissionProps {
  clientUnit: ClienteUnidade;
}

export const ClientUnitDocumentsPermission = ({ clientUnit }: ClientUnitDocumentsPermissionProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [, { error, touched, value: allUnits }, { setValue }] = useField('unidades');
  const formik = useFormikContext();

  const [allowedDocumentsTemp, setAllowedDocumentsTemp] = useState<{ [key: number]: boolean; }>({});

  useEffect(() => {
    setAllowedDocumentsTemp(clientUnit.allowed_documents ?? {});
  }, [clientUnit]);

  const updateClientUnit = (values: ClienteUnidade) => {
    const units = JSON.parse(JSON.stringify(allUnits));
    const unitIndex = units.findIndex((unit: ClienteUnidade) => unit.id === values.id);
    units[unitIndex] = {
      ...values,
      allowed_documents: allowedDocumentsTemp,
    };
    setValue(units);
    formik.submitForm();
  };

  const activeDocuments = useSelector((state: RootState) => state.company?.activeDocuments ?? []);

  return (
    <React.Fragment>
      {clientUnit?.id && <Button onClick={onOpen} colorScheme="blue" size="sm">
        <div className="w-full flex flex-col items-center uppercase">
          <small>{t('PermissionsTo')}</small>
          <small>{t('Documentation')}</small>
        </div>
      </Button>}
      <Modal scrollBehavior="inside" closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"5xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={`${t('PermissionsTo')} ${t('Documentation')}`} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {clientUnit && <Formik
              enableReinitialize
              initialValues={clientUnit}
              onSubmit={(val) => {
                updateClientUnit({
                  ...val,
                  changed: true,
                });
                onClose();
              }}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <VStack spacing={4} align="stretch">
                    <Text>{t('SelectDocumentsToAllow')}</Text>
                    {JSON.stringify(allowedDocumentsTemp)}
                    <Documents
                      documents={activeDocuments}
                      selectionMode={true}
                      selectedDocuments={allowedDocumentsTemp ?? {}}
                      onSelectionChange={(documentId: string, selected: boolean) => {
                        setAllowedDocumentsTemp((oldVal) => ({
                          ...oldVal,
                          [documentId]: selected,
                        }))
                      }}
                    />
                    <Button type="submit" colorScheme="blue">
                      {t('Save')}
                    </Button>
                  </VStack>
                </Form>
              )}
            </Formik>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};
