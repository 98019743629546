import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { mainSaga } from "./mainSaga";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import sistemaSlice from "../sistema/recucer";
import leiturasSlice from "../leituras/reducer";
import bodyCenterSlice from "../body-center/reducer";
import companySlice from "../company/reducer";

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  sistema: persistReducer({
    key: "ecozone_app_sistema",
    storage,
  }, sistemaSlice.reducer),
  leituras: persistReducer({
    key: "ecozone_app_leituras",
    storage,
  }, leiturasSlice.reducer),
  bodyCenter: bodyCenterSlice.reducer,
  company: companySlice.reducer,
});

let middlewares: any = [sagaMiddleware];
if (process.env.NODE_ENV === "development") {
  //middlewares = [...middlewares, createLogger()];
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares,
});
export const persistor = persistStore(store);

sagaMiddleware.run(mainSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
