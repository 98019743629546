import { PayloadAction } from "@reduxjs/toolkit";
import { put, all, takeLatest, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { companyActions } from "./reducer";
import { apiCall } from "../utils/apiCall";
import { formatError } from "../utils/formatError";
import { ListWithFiltersPayload } from "../sistema/types/listPayload";
import { ApiPaginationRes } from "../sistema/types/apiPaginationRes";
import { CompanyDocument } from "./types/company_document";

// Worker Sagas
function* documentsRequestWorker({ payload }: PayloadAction<ListWithFiltersPayload>) {
  try {
    const { filters, page } = payload;
    const queryParams = new URLSearchParams();

    queryParams.append('page', String(page));

    if (filters.category) queryParams.append('category', filters.category);
    if (filters.subcategory) queryParams.append('subcategory', filters.subcategory);
    if (filters.active !== undefined) queryParams.append('active', String(filters.active));
    if (filters.item_name) queryParams.append('item_name', filters.item_name);
    if (filters.item_due_date_start) queryParams.append('item_due_date_start', filters.item_due_date_start);
    if (filters.item_due_date_end) queryParams.append('item_due_date_end', filters.item_due_date_end);
    if (filters.item_technical) queryParams.append('item_technical', filters.item_technical);

    const res: AxiosResponse<ApiPaginationRes<CompanyDocument[]>> = yield call(
      apiCall,
      {
        url: `company-documents-list?${queryParams.toString()}`,
        method: "get",
      }
    );
    yield put(companyActions.documentsRequestSuccess(res.data));
  } catch (error: any) {
    yield put(companyActions.error(formatError(error)));
  }
}

function* activeDocumentsRequestWorker() {
  try {
    const res: AxiosResponse<CompanyDocument[]> = yield call(apiCall, {
      url: `company-documents-active`,
      method: "get",
    });
    yield put(companyActions.activeDocumentsRequestSuccess(res.data));
  } catch (error: any) {
    yield put(companyActions.error(formatError(error)));
  }
}

function* documentRequestWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse<CompanyDocument> = yield call(apiCall, {
      url: `company-documents/${payload}?include=items,user`,
      method: "get",
    });
    yield put(companyActions.documentRequestSuccess(res.data));
  } catch (error: any) {
    yield put(companyActions.error(formatError(error)));
  }
}

function* saveDocumentRequestWorker({ payload }: PayloadAction<CompanyDocument>) {
  try {
    const res: AxiosResponse<CompanyDocument> = yield call(apiCall, {
      url: `company-documents?include=items,user`,
      method: "post",
      data: payload,
    });
    yield put(companyActions.saveDocumentRequestSuccess(res.data));
  } catch (error: any) {
    yield put(companyActions.error(formatError(error)));
  }
}

function* deleteDocumentRequestWorker({ payload }: PayloadAction<{ id: string; list: ListWithFiltersPayload }>) {
  try {
    yield call(apiCall, {
      url: `company-documents/${payload.id}`,
      method: "delete",
    });
    yield put(companyActions.deleteDocumentRequestSuccess());
    
    yield put(companyActions.documentsRequest(payload.list));
  } catch (error: any) {
    yield put(companyActions.error(formatError(error)));
  }
}

export function* companySaga() {
  yield all([
    takeLatest("company/documentsRequest", documentsRequestWorker),
    takeLatest("company/documentRequest", documentRequestWorker),
    takeLatest("company/saveDocumentRequest", saveDocumentRequestWorker),
    takeLatest("company/deleteDocumentRequest", deleteDocumentRequestWorker),
    takeLatest("company/activeDocumentsRequest", activeDocumentsRequestWorker),
  ]);
}
