import { FiCalendar, FiFileText, FiImage } from "react-icons/fi";
import { ClienteUnidade } from "../../sistema/types/cliente_unidade"
import { formatData } from "../../utils/data";
import { useTranslation } from "react-i18next";
import { UnitDetailsModal } from "./UnitDetailsModal";
import { useState } from "react";
import { Image, useDisclosure } from "@chakra-ui/react";
import { RootState } from "../../app/mainReducer";
import { useSelector } from "react-redux";
import ReactSpeedometer, { CustomSegmentLabelPosition } from "react-d3-speedometer";
import { BodyCenterVisitSighting } from "../types/body_center_visit_sighting";
import { BodyCenterVisitTrace } from "../types/body_center_visit_trace";
import { BodyCenterVisitOccurrence } from "../types/body_center_visit_occurrence";
import { getBaitsTypes } from "../utils/bodyCenterBaits";
import { formatValor } from "../../utils/formatValor";
import { DownloadCompanyDocumentsModal } from "./DownloadCompanyDocumentsModal";
import { isConsumed } from "../utils/occurrenceUtils";

type UnitCardProps = {
  unit: ClienteUnidade;
}

type IndicatorResult = {
  value: number;
  name: string;
  color?: 'red' | 'yellow' | 'green';
}

export const UnitCard = ({ unit }: UnitCardProps) => {
  const { t } = useTranslation();


  const calculateConsumptionIndicator = (occurrences: BodyCenterVisitOccurrence[]): IndicatorResult => {
    const totalBaits = occurrences.filter(o => o.bait_type > 0);
    const consumedBaits = totalBaits.filter(o => isConsumed(o)).length;
    const percentage = totalBaits ? (consumedBaits / totalBaits.length) * 100 : 0;

    const value = percentage <= 0 ? 0 : (percentage > 100 ? 100 : percentage);

    return {
      value: isNaN(value) ? 0 : value,
      name: t('BodyCenterBaitConsumptionQuantityName'),
    };
  };

  const calculateTracesIndicator = (traces: BodyCenterVisitTrace[]): IndicatorResult => {
    const sectors = new Set([
      ...(traces?.map((i) => i.sector) ?? []),
    ]);

    const sectorsWithTraces = new Set([
      ...(traces.filter(t => t.quantity > 0).map(t => t.sector) ?? []),
    ]);
    const percentage = sectors.size ? (sectorsWithTraces.size / sectors.size) * 100 : 0;

    const value = percentage <= 0 ? 0 : (percentage > 100 ? 100 : percentage);

    return {
      value: isNaN(value) ? 0 : value,
      name: t('BodyCenterTracesQuantityName'),
    };
  };

  const calculateSightingsIndicator = (sightings: BodyCenterVisitSighting[]): IndicatorResult => {
    const sectors = new Set([
      ...(sightings?.map((i) => i.sector) ?? []),
    ]);

    const sectorsWithSightings = new Set([
      ...(sightings.filter(s => s.quantity_alive > 0 || s.quantity_death > 0).map(s => s.sector) ?? []),
    ]);
    const percentage = sectors.size ? (sectorsWithSightings.size / sectors.size) * 100 : 0;


    const value = percentage <= 0 ? 0 : (percentage > 100 ? 100 : percentage);

    return {
      value: isNaN(value) ? 0 : value,
      name: t('BodyCenterSightingsQuantityName'),
    };
  };

  const getColorByPercentage = (value: number): 'red' | 'yellow' | 'green' => {
    if (value >= 70) return 'red';
    if (value >= 40) return 'yellow';
    return 'green';
  };

  const calculateOverallIndicator = (): IndicatorResult => {
    const indicators = [
      calculateConsumptionIndicator(unit.bodyCenterVisits?.[0]?.occurrences ?? []),
      calculateTracesIndicator(unit.bodyCenterVisits?.[0]?.traces ?? []),
      calculateSightingsIndicator(unit.bodyCenterVisits?.[0]?.sightings ?? []),
    ];

    const average = indicators.reduce((acc, curr) => acc + curr.value, 0) / indicators.length;

    return {
      value: average < 0 ? 0 : (average > 100 ? 100 : average),
      name: t('BodyCenterOverallIndicator'),
      color: getColorByPercentage(average),
    };
  };

  const color: string = calculateOverallIndicator()?.color ?? 'green';

  const getClasses = (status: 'red' | 'yellow' | 'green', isPing: boolean = false) => {
    if (color == 'red') {
      if (status == 'red') return isPing ? 'z-10 bg-red-500 animate-ping' : 'bg-red-500';
      if (status == 'yellow') return 'bg-yellow-100';
      if (status == 'green') return 'bg-green-100';
    }
    if (color == 'yellow') {
      if (status == 'red') return 'bg-red-100';
      if (status == 'yellow') return isPing ? 'z-10 bg-yellow-500 animate-ping' : 'bg-yellow-500';
      if (status == 'green') return 'bg-green-100';
    }
    if (color == 'green') {
      if (status == 'red') return 'bg-red-100';
      if (status == 'yellow') return 'bg-yellow-100';
      if (status == 'green') return 'bg-green-500';
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const isFullHd = window.innerWidth >= 1920;

  const betweenNormalToFullHd = window.innerWidth >= 1745;

  const Speedometer = ({ value }: { value: IndicatorResult }) => {
    return <ReactSpeedometer
      customSegmentLabels={[
        {
          text: "0-39%",
          position: CustomSegmentLabelPosition.Outside,
          color: "#555",
          fontSize: "8px",
        },
        {
          text: "40-69%",
          position: CustomSegmentLabelPosition.Outside,
          color: "#555",
          fontSize: "8px"
        },
        {
          text: "70-100%",
          position: CustomSegmentLabelPosition.Outside,
          color: "#555",
          fontSize: "8px"
        }
      ]}
      currentValueText={`${value.name}: ${formatValor(value.value)}%`}
      value={value.value}
      minValue={0}
      maxValue={100}
      needleColor="#888"
      startColor="#FF6347"
      endColor="#00FF7F"
      segments={3}
      needleHeightRatio={0.7}
      needleTransitionDuration={1000}
      customSegmentStops={[0, 39, 69, 100]}
      segmentColors={['#00FF7F', '#FFD700', '#FF6347']}
      height={isFullHd ? 130 : betweenNormalToFullHd ? 115 : 95}
      width={isFullHd ? 160 : betweenNormalToFullHd ? 150 : 135}
      ringWidth={isFullHd ? 30 : betweenNormalToFullHd ? 25 : 20}
      labelFontSize="8"
      valueTextFontSize="12"

    />
  }

  const [isLoading, setIsLoading] = useState(true);

  const activeDocuments = useSelector((state: RootState) => state.company?.activeDocuments ?? []);

  const commomDocuments = useSelector((state: RootState) => state.bodyCenter?.currentDocuments ?? []);

  let commomDocumentsIds: string[] = [];

  for (const doc of commomDocuments) {
    for (const item of doc.items) {
      commomDocumentsIds.push(String(item.id));
    }
  }

  const permissionOfUnit = Object.entries(unit.allowed_documents ?? {}).reduce((acc, [key, value]) => {
    if (value === true) {
      acc[Number(key)] = value;
    }
    return acc;
  }, {} as Record<number, boolean>);

  const documentsOfUnit = activeDocuments.map((document) => {
    return {
      ...document,
      items: document.items.filter((item) => {
        return !commomDocumentsIds.includes(String(item.id)) && permissionOfUnit[Number(item.id)] === true;
      })
    }
  }).filter((document) => document.items.length > 0);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div className="w-full p-0">
      <div onClick={() => setIsModalOpen(true)} className={`bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 p-3 sm:p-1 cursor-pointer ${!isMobile ? 'hover:scale-[1.008]' : ''}`}>
        <div className="flex items-center justify-between mb-1 px-2">
          <h3 className="text-lg md:text-sm font-semibold text-gray-800 sm:truncate">
            {unit.nome_referencia}
          </h3>
          <div className="flex items-center gap-1">

            <div className={`w-4 h-4 relative ${isFullHd ? 'md:w-6 md:h-6' : 'md:w-4 md:h-4'} rounded-full transition-all duration-300 ${getClasses('red')}`}>
              {color == 'red' && <div className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${getClasses('red', true)}`} />}
            </div>
            <div className={`w-4 h-4 relative ${isFullHd ? 'md:w-6 md:h-6' : 'md:w-4 md:h-4'} rounded-full transition-all duration-300 ${getClasses('yellow')}`}>
              {color == 'yellow' && <div className={`animate-ping absolute inline-flex h-full w-full rounded-full opacity-75 ${getClasses('yellow', true)}`} />}
            </div>
            <div className={`w-4 h-4 ${isFullHd ? 'md:w-6 md:h-6' : 'md:w-4 md:h-4'} rounded-full transition-all duration-300 ${getClasses('green')}`} />
          </div>
        </div>

        {(!isMobile || (isMobile && unit.imagem)) && (
          <div className={`relative w-full ${isFullHd ? 'h-52 pt-1' : betweenNormalToFullHd ? 'h-48' : 'h-40'} mb-3 px-2`}>
            <div className="absolute top-3 left-2 z-10">
              <div className="flex opacity-90 items-center gap-1 bg-white backdrop-blur-sm text-gray-700 text-xs font-medium px-2 py-1 rounded-r shadow-r-sm transition-all duration-200">
                <FiCalendar className="w-3 h-3 text-green-600" />
                <span>{(unit.bodyCenterVisits ?? []).length}</span>
                <span className="text-gray-700">{((unit.bodyCenterVisits ?? []).length == 0 || (unit.bodyCenterVisits ?? []).length > 1) ? t('BodyCenterSelectClientTotalVisits') : t('BodyCenterSingular')}</span>
              </div>
            </div>
            <div className="absolute top-3 right-2 z-10">
              {documentsOfUnit.length > 0 && <>
                <DownloadCompanyDocumentsModal
                  isOpen={isOpen}
                  onClose={onClose}
                  documents={documentsOfUnit}
                />
                <button
                  onClick={(e) => {
                    e.stopPropagation();

                    onOpen();
                  }}
                  className="flex opacity-90 items-center gap-1 bg-white backdrop-blur-sm hover:bg-gray-50 text-gray-700 px-2 py-1 rounded-l shadow-sm transition-all duration-200"
                >
                  <FiFileText className="w-3.5 h-3.5 text-blue-600" />
                  <span className="text-xs font-medium">{t('Docs')}</span>
                </button></>}
            </div>
            {unit.imagem ? (
              <div className="relative w-full h-full">
                <div className="absolute inset-0 bg-gray-100 rounded-md flex items-center justify-center transition-opacity duration-300"
                  style={{ opacity: isLoading ? 1 : 0 }}>
                  <div className="w-12 h-12 border-4 border-green-500 border-t-transparent rounded-full animate-spin" />
                </div>
                <Image
                  src={unit.imagem}
                  alt={unit.nome_referencia}
                  className="w-full h-full object-cover rounded-md transition-opacity duration-300"
                  style={{ opacity: isLoading ? 0 : 1 }}
                  onLoad={() => setIsLoading(false)}
                  fallback={
                    <div className="w-full h-full bg-gray-100 rounded-lg flex flex-col items-center justify-center">
                      <FiImage className="w-12 h-12 text-gray-300" />
                    </div>
                  }
                />
              </div>
            ) : (
              <div className="w-full h-full bg-gray-100 rounded-lg flex flex-col items-center justify-center">
                <FiImage className="w-12 h-12 text-gray-300" />
              </div>
            )}
          </div>
        )}

        <div className="">
          <div className="w-full">
            <div className="w-full">
              <div className="w-full text-center">
                <p className="text-xs font-bold text-gray-600">
                  {t('BodyCenterIndicatorsLastVisit')} ({unit.bodyCenterVisits?.[0]?.date ? (formatData(unit.bodyCenterVisits[0].date, 'dd/MM/yyyy') + (unit.bodyCenterVisits[0].service_number ? (' - OSC: ' + unit.bodyCenterVisits[0].service_number) : '')) : t('BodyCenterNaoDisponivel')})
                </p>

                <div className="w-full flex flex-wrap justify-around">
                  <div className={`${isMobile ? 'w-1/2' : 'w-1/3'} flex justify-center`}>
                    <Speedometer value={calculateConsumptionIndicator(unit.bodyCenterVisits?.[0]?.occurrences ?? [])} />
                  </div>

                  <div className="${isMobile ? 'w-1/2' : 'w-1/3'} flex justify-center">
                    <Speedometer value={calculateTracesIndicator(unit.bodyCenterVisits?.[0]?.traces ?? [])} />
                  </div>

                  <div className="${isMobile ? 'w-1/2' : 'w-1/3'} flex justify-center">
                    <Speedometer value={calculateSightingsIndicator(unit.bodyCenterVisits?.[0]?.sightings ?? [])} />
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>

        <div>
          <UnitDetailsModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            unit={unit}
          />
        </div>
      </div>
    </div>
  )
}