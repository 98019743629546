import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import { useParams } from "react-router-dom";
import React, { useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Badge, Button, Flex } from "@chakra-ui/react";
import { ListHeader } from "../../sistema/components/ListHeader";
import Layout from "../../sistema/components/Layout";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import validateForm from "../../utils/validateForm";
import { useTranslation } from "react-i18next";
import InputField from "../../sistema/components/InputField";
import InputSelect from "../../sistema/components/InputSelect";
import { BackButton } from "../../sistema/components/BackButton";
import { Filhos } from "../../sistema/components/Filhos";
import { FiEdit, FiSave, FiDownload } from "react-icons/fi";
import { companyActions } from "../reducer";
import { TableHeaders } from "../../sistema/components/TableFixed";
import { openLink } from "../../utils/openLink";
import { CompanyDocumentItem } from "../types/company_document_item";
import { newDocument } from "../data/company_document";
import { leituraActions } from "../../leituras/reducer";
import InputCheckInteiro from "../../sistema/components/InputCheckInteiro";
import { newDocumentItem } from "../data/company_document_item";
import { formatData } from "../../utils/data";
import InputFile from "../../sistema/components/InputFile";

export const CompanyDocumentEdit = () => {
  useIsAuth();

  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const document = useSelector((state: RootState) => state.company.document);
  const isLoading = useSelector((state: RootState) => !!state.company.isLoading);
  const operators = useSelector((state: RootState) => state.leituras?.leituraDadosSelectsInputs)
  const activeDocuments = useSelector((state: RootState) => state.company?.activeDocuments ?? []);

  useEffect(() => {
    dispatch(leituraActions.leituraDadosSelectsInputs());
    dispatch(companyActions.activeDocumentsRequest());

    if (!id || id === 'novo') {
      dispatch(companyActions.documentRequestSuccess(newDocument()));
      return;
    }

    dispatch(companyActions.documentRequest(id));
  }, [dispatch, id]);

  const getSubcategoriesOfCategory = (category: string) => {
    return Array.from(new Set(activeDocuments.filter((o) => o.category == category && !!o.subcategory).map((o) => o.subcategory)));
  }

  return (
    <Layout>
      <ListHeader label={t('CompanyDocument')}>
        <BackButton ml={4} to="/company-documents">
          {t('Back')}
        </BackButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {document && (
        <Formik
          initialValues={document}
          enableReinitialize
          onSubmit={(values, { setErrors }) => {
            const validation = validateForm({
              category: 'required',
            }, values, t);

            if (Object.keys(validation).length > 0) {
              setErrors(validation);
              return;
            }

            dispatch(companyActions.saveDocumentRequest({
              ...values,
              changed: true,
            }));
          }}
        >
          {({ values, submitForm }) => (
            <Form>
              <Flex width="full" wrap="wrap">
                <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4">
                  <InputField
                    name="category"
                    label={t('Category')}
                  />

                  <InputField
                    name="subcategory"
                    label={t('Subcategory')}
                  />

                  <InputSelect
                    name="user_id"
                    label={t('Operator')}
                  >
                    {(operators?.administradores ?? []).map((operator) => (
                      <option value={operator.id}>
                        {operator.name} {operator.celular}
                      </option>
                    ))}
                  </InputSelect>

                  <small className="md:col-span-3">
                    <b>{t('Já cadastrados')}:</b> {Array.from(new Set(activeDocuments.map((i) => `${i.category}${getSubcategoriesOfCategory(i.category).length ? ` (${getSubcategoriesOfCategory(i.category).join(', ')})` : ''
                      }`))).join(', ')}
                  </small>

                  <InputCheckInteiro
                    label={`${t('Active')}?`}
                    name="active"
                  />
                </div>

                <div className="w-full mt-6">
                  <Filhos
                    name="items"
                    tituloForm={t('Documents')}
                    editTituloForm={t('Document')}
                    novoItemObject={newDocumentItem()}
                    validation={{
                      name: 'required',
                      file: 'required'
                    }}
                    afterSave={submitForm}
                    headers={[
                      {
                        label: t('DashboardNome'),
                        wrapped: false,
                        render: (item) => item.name
                      },
                      {
                        label: t('DueDate'),
                        wrapped: false,
                        render: (item) => formatData(item.due_date, 'dd/MM/yyyy')
                      },
                      {
                        label: t('Status'),
                        wrapped: false,
                        render: (item) => (
                          <Badge colorScheme={item.active ? "blue" : "red"}>
                            {item.active ? t('Active') : t('Inactive')}
                          </Badge>
                        )
                      },
                      {
                        label: t('File'),
                        wrapped: false,
                        notClicable: true,
                        render: (item) => (<React.Fragment>
                          {item.file && item.file.includes('http') && <Button
                            size="sm"
                            colorScheme="blue"
                            leftIcon={<FiDownload />}
                            onClick={() => openLink(item.file)}
                          >
                            {t('Download')}
                          </Button>}</React.Fragment>
                        )
                      },
                    ] as TableHeaders<CompanyDocumentItem>[]}
                    form={() => (
                      <Flex w="full" wrap="wrap" gap={4}>
                        <InputField
                          className="w-full md:w-1/2"
                          name="name"
                          label={t('DashboardNome')}
                        />

                        <InputField
                          className="w-full md:w-1/2"
                          name="due_date"
                          label={t('DueDate')}
                          type="date"
                        />


                        <InputCheckInteiro
                          label={`${t('Active')}?`}
                          name="active"
                        />

                        <InputFile name="file" label={t('SistemaArquivoUrl')} />
                      </Flex>
                    )}
                  />
                </div>

                <hr className="w-full my-6" />

                <DefaultButton
                  type="submit"
                  className="flex items-center gap-2"
                >
                  <FiSave /> {t('Save')} {t('CompanyDocument')}
                </DefaultButton>
              </Flex>
            </Form>
          )}
        </Formik>
      )}
    </Layout>
  );
};
