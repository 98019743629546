import { Grid, Box, ModalBody, Modal, Icon, ModalOverlay, ModalContent, ModalCloseButton, Text, useDisclosure, IconButton } from "@chakra-ui/react";
import React from "react";
import { ClienteUnidade } from "../../sistema/types/cliente_unidade";
import { ReactECharts } from "../../leituras/components/ReactECharts";
import { getBaitsTypes } from "../utils/bodyCenterBaits";
import { BodyCenterVisitOccurrence } from "../types/body_center_visit_occurrence";
import { useTranslation } from "react-i18next";
import { BodyCenterVisit } from "../types/body_center_visit";
import { formatData } from "../../utils/data";
import { FaExpand } from "react-icons/fa";
import { useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { NoVisitsAlert } from "./NoVisitsAlert";
import { isConsumed } from "../utils/occurrenceUtils";

type ThirdTabProps = {
  unit: ClienteUnidade;
  selectedVisits: BodyCenterVisit[];
};

const Item = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      bg="white"
      p={6}
      rounded="xl"
      shadow="sm"
      borderWidth={1}
      borderColor="gray.200"
      height="300px"
      position="relative"
    >
      {children}
    </Box>
  )
}


export const ThirdTab = ({ unit, selectedVisits }: ThirdTabProps) => {
  const { t } = useTranslation();

  const orderedVisits: BodyCenterVisit[] = JSON.parse(JSON.stringify(selectedVisits)).sort(function (a: any, b: any) {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const graph1 = {
    title: {
      text: t('BodyCenterConsumptionTrend'),
      textStyle: {
        color: '#2D3748',
        fontSize: 16,
        fontWeight: 'bold'
      }
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      borderColor: '#E2E8F0',
      textStyle: {
        color: '#4A5568'
      },
      formatter: (params: any) => {
        const data = params[0];
        return `${data.name}<br/>${t('BodyCenterBaitConsumptionQuantity')}: ${data.value}`;
      }
    },
    grid: {
      left: '5%',
      right: '5%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: orderedVisits.map(visit => formatData(visit.date, 'dd/MM/yyyy')),
      axisLabel: {
        rotate: 45,
        color: '#718096'
      },
      axisLine: {
        lineStyle: {
          color: '#CBD5E0'
        }
      }
    },
    yAxis: {
      type: 'value',
      nameTextStyle: {
        color: '#718096'
      },
      axisLabel: {
        color: '#718096'
      },
      splitLine: {
        lineStyle: {
          color: '#E2E8F0'
        }
      }
    },
    series: [{
      name: t('ConsumedBaits'),
      type: 'line',
      data: orderedVisits.map(visit => (visit?.occurrences ?? []).filter(occurrence => isConsumed(occurrence)).length),
      smooth: true,
      symbol: 'circle',
      symbolSize: 8,
      itemStyle: {
        color: '#9F7AEA' // Changed to purple
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 0,
            color: 'rgba(159, 122, 234, 0.3)' // Changed to purple with opacity
          }, {
            offset: 1,
            color: 'rgba(159, 122, 234, 0.05)' // Changed to purple with lower opacity
          }]
        }
      },
      emphasis: {
        itemStyle: {
          borderWidth: 3,
          shadowBlur: 10,
          shadowColor: 'rgba(159, 122, 234, 0.5)' // Changed to purple with opacity
        }
      }
    }]
  } as any

  const graph2 = {
    title: {
      text: t('BodyCenterTracesTrend'),
      textStyle: {
        color: '#1A202C',
        fontSize: 16,
        fontWeight: 'bold'
      }
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(45, 55, 72, 0.95)',
      borderColor: '#4A5568',
      textStyle: {
        color: '#FFFFFF'
      },
      formatter: (params: any) => {
        const data = params[0];
        return `${data.name}<br/>${t('BodyCenterTracesQuantity')}: ${data.value}`;
      }
    },
    grid: {
      left: '5%',
      right: '5%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: orderedVisits.map(visit => formatData(visit.date, 'dd/MM/yyyy')),
      axisLabel: {
        rotate: 45,
        color: '#4A5568'
      },
      axisLine: {
        lineStyle: {
          color: '#718096'
        }
      }
    },
    yAxis: {
      type: 'value',
      nameTextStyle: {
        color: '#4A5568'
      },
      axisLabel: {
        color: '#4A5568'
      },
      splitLine: {
        lineStyle: {
          color: '#718096',
          width: 0.5,
          type: 'dashed'
        }
      }
    },
    series: [{
      name: t('ConsumedBaits'),
      type: 'line',
      data: orderedVisits.map(visit => (visit?.traces ?? []).reduce((acc, trace) => acc + trace.quantity, 0)),
      smooth: true,
      symbol: 'circle',
      symbolSize: 8,
      itemStyle: {
        color: '#4A5568'
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 0,
            color: 'rgba(74, 85, 104, 0.2)'
          }, {
            offset: 1,
            color: 'rgba(74, 85, 104, 0.05)'
          }]
        }
      },
      emphasis: {
        itemStyle: {
          borderWidth: 3,
          shadowBlur: 10,
          shadowColor: 'rgba(74, 85, 104, 0.5)'
        }
      }
    }]
  } as any

  const graph3 = {
    title: {
      text: t('BodyCenterSightingTrend'),
      textStyle: {
        color: '#2D3748',
        fontSize: 16,
        fontWeight: 'bold'
      }
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      borderColor: '#E2E8F0',
      textStyle: {
        color: '#4A5568'
      },
      formatter: (params: any) => {
        const date = params[0].name;
        return `${date}<br/>${params.map((param: any) => {
          return `${param.seriesName}: ${param.value}`;
        }).join('<br/>')}`;
      }
    },
    grid: {
      left: '5%',
      right: '5%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: orderedVisits.map(visit => formatData(visit.date, 'dd/MM/yyyy')),
      axisLabel: {
        rotate: 45,
        color: '#718096'
      },
      axisLine: {
        lineStyle: {
          color: '#CBD5E0'
        }
      }
    },
    yAxis: {
      type: 'value',
      nameTextStyle: {
        color: '#718096'
      },
      axisLabel: {
        color: '#718096'
      },
      splitLine: {
        lineStyle: {
          color: '#E2E8F0'
        }
      }
    },
    series: [
      {
        name: t('BodyCenterAlive'),
        type: 'line',
        data: orderedVisits.map(visit => (visit?.sightings ?? []).reduce((acc, sighting) => acc + sighting.quantity_alive, 0)),
        smooth: true,
        symbol: 'circle',
        symbolSize: 8,
        itemStyle: {
          color: '#48BB78'
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 0,
              color: 'rgba(72, 187, 120, 0.3)'
            }, {
              offset: 1,
              color: 'rgba(72, 187, 120, 0.05)'
            }]
          }
        }
      },
      {
        name: t('BodyCenterDeath'),
        type: 'line',
        data: orderedVisits.map(visit => (visit?.sightings ?? []).reduce((acc, sighting) => acc + sighting.quantity_death, 0)),
        smooth: true,
        symbol: 'circle',
        symbolSize: 8,
        itemStyle: {
          color: '#E53E3E'
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [{
              offset: 0,
              color: 'rgba(229, 62, 62, 0.3)'
            }, {
              offset: 1,
              color: 'rgba(229, 62, 62, 0.05)'
            }]
          }
        }
      }
    ]
  } as any

  const graph4 = {
    title: {
      text: t('BodyCenterNonConformitiesTrend'),
      textStyle: {
        color: '#2D3748',
        fontSize: 16,
        fontWeight: 'bold'
      }
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
      borderColor: '#E2E8F0',
      textStyle: {
        color: '#4A5568'
      },
      formatter: (params: any) => {
        const data = params[0];
        return `${data.name}<br/>${t('BodyCenterTotalNonConformities')}: ${data.value}`;
      }
    },
    grid: {
      left: '5%',
      right: '5%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: orderedVisits.map(visit => formatData(visit.date, 'dd/MM/yyyy')),
      axisLabel: {
        rotate: 45,
        color: '#718096'
      },
      axisLine: {
        lineStyle: {
          color: '#CBD5E0'
        }
      }
    },
    yAxis: {
      type: 'value',
      nameTextStyle: {
        color: '#718096'
      },
      axisLabel: {
        color: '#718096'
      },
      splitLine: {
        lineStyle: {
          color: '#E2E8F0'
        }
      }
    },
    series: [{
      name: t('ConsumedBaits'),
      type: 'line',
      data: orderedVisits.map(visit => (visit?.nonconformities ?? []).reduce((acc, nonConformity) => acc + nonConformity.quantity, 0)),
      smooth: true,
      symbol: 'circle',
      symbolSize: 8,
      itemStyle: {
        color: '#E53E3E'
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [{
            offset: 0,
            color: 'rgba(229, 62, 62, 0.4)'
          }, {
            offset: 1,
            color: 'rgba(229, 62, 62, 0.1)'
          }]
        }
      },
      emphasis: {
        itemStyle: {
          borderWidth: 3,
          shadowBlur: 10,
          shadowColor: 'rgba(229, 62, 62, 0.5)'
        }
      }
    }]
  } as any

  const { isOpen: isOpenGraph1, onOpen: onOpenGraph1, onClose: onCloseGraph1 } = useDisclosure();
  const { isOpen: isOpenGraph2, onOpen: onOpenGraph2, onClose: onCloseGraph2 } = useDisclosure();
  const { isOpen: isOpenGraph3, onOpen: onOpenGraph3, onClose: onCloseGraph3 } = useDisclosure();
  const { isOpen: isOpenGraph4, onOpen: onOpenGraph4, onClose: onCloseGraph4 } = useDisclosure();

  if (!selectedVisits.length) {
    return <NoVisitsAlert />
  }

  return (
    <React.Fragment>
      <Grid templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]} gap={4}>
        <Item>
          <ReactECharts
            option={graph1}
            style={{ height: '290px', width: isMobile ? '300px' : '480px' }}
          />
          <IconButton
            aria-label=""
            icon={<Icon as={FaExpand} />}
            position="absolute"
            top={4}
            right={4}
            size="sm"
            onClick={onOpenGraph1}
            zIndex={10000}
          />
          <Modal isOpen={isOpenGraph1} onClose={onCloseGraph1} size="full">
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton className="z-50" />
              <ModalBody>
                <Text fontSize="sm" my={4}>
                  {isMobile && t('RotateDeviceHint')}
                </Text>
                <ReactECharts
                  option={graph1}
                  style={{ height: '95vh', width: '100%' }}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Item>

        <Item>
          <ReactECharts
            option={graph2}
            style={{ height: '290px', width: isMobile ? '300px' : '480px' }}
          />
          <IconButton
            aria-label=""
            icon={<Icon as={FaExpand} />}
            position="absolute"
            top={4}
            right={4}
            size="sm"
            onClick={onOpenGraph2}
            zIndex={10000}
          />
          <Modal isOpen={isOpenGraph2} onClose={onCloseGraph2} size="full">
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton className="z-50" />
              <ModalBody>
                <Text fontSize="sm" my={4}>
                  {isMobile && t('RotateDeviceHint')}
                </Text>
                <ReactECharts
                  option={graph2}
                  style={{ height: '95vh', width: '100%' }}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Item>


        <Item>
          <ReactECharts
            option={graph3}
            style={{ height: '290px', width: isMobile ? '300px' : '480px' }}
          />
          <IconButton
            aria-label=""
            icon={<Icon as={FaExpand} />}
            position="absolute"
            top={4}
            right={4}
            size="sm"
            onClick={onOpenGraph3}
            zIndex={10000}
          />
          <Modal isOpen={isOpenGraph3} onClose={onCloseGraph3} size="full">
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton className="z-50" />
              <ModalBody>
                <Text fontSize="sm" my={4}>
                  {isMobile && t('RotateDeviceHint')}
                </Text>
                <ReactECharts
                  option={graph3}
                  style={{ height: '95vh', width: '100%' }}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Item>


        <Item>
          <ReactECharts
            option={graph4}
            style={{ height: '290px', width: isMobile ? '300px' : '480px' }}
          />
          <IconButton
            aria-label=""
            icon={<Icon as={FaExpand} />}
            position="absolute"
            top={4}
            right={4}
            size="sm"
            onClick={onOpenGraph4}
            zIndex={10000}
          />
          <Modal isOpen={isOpenGraph4} onClose={onCloseGraph4} size="full">
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton className="z-50" />
              <ModalBody>
                <Text fontSize="sm" my={4}>
                  {isMobile && t('RotateDeviceHint')}
                </Text>
                <ReactECharts
                  option={graph4}
                  style={{ height: '95vh', width: '100%' }}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Item>
      </Grid>
    </React.Fragment>
  )
};
