import { useField, Formik, Form, useFormikContext } from "formik";
import ResponsiveTable, { TableHeaders } from "./ResponsiveTable";
import { ClienteUnidade } from "../types/cliente_unidade";
import { ListHeaderSecondary } from "./ListHeaderSecondary";
import { DefaultButton } from "./DefaultButton";
import { Badge, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure } from "@chakra-ui/react";
import { useState, useEffect } from 'react'
import { novoClienteUnidade } from "../data/cliente_unidade";
import validateForm from "../../utils/validateForm";
import InputField from "./InputField";
import InputCheck from "./InputCheck";
import { ClienteUnidadesResponsaveis } from "./ClienteUnidadesResponsaveis";
import axios from "axios";
import { FiTrash } from "react-icons/fi";
import { ClienteUnidadesSilos } from "./ClienteUnidadesSilos";
import { apiCall } from "../../utils/apiCall";
import { RootState } from "../../app/mainReducer";
import { useSelector } from "react-redux";
import InputImage from "./InputImage";
import InputGeolocalizacao from "./InputGeolocalizacao";
import { useTranslation } from "react-i18next";
import { mensagemErro } from "../../utils/toasts";
import { formatError } from "../../utils/formatError";
import { ClientUnitBaitMap } from "./ClientUnitBaitMap";
import InputCheckInteiro from "./InputCheckInteiro";
import { Documents } from "../../company/components/Documents";
import { ClientUnitDocumentsPermission } from "./ClientUnitDocumentsPermission";

export const ClienteUnidades = () => {
  const [, { value }, { setValue }] = useField('unidades');
  const formik = useFormikContext();

  const eDesenvolvedor = useSelector((state: RootState) => (state.sistema.user as any)?.e_desenvolvedor == 1);
  const { t } = useTranslation()

  const headers: TableHeaders<ClienteUnidade>[] = [
    {
      label: t('ClienteEditUnidadeListNome'),
      wrapped: false,
      render: (item) => <>
        {item.nome_referencia} <br />
        <small>{`${item.cep ? `${item.cep} - ` : ''} ${item.cidade ?? ''} ${item.uf ? `(${item.uf})` : ''}`}</small> <br />
        <Badge colorScheme={(item.ativo as any) == 1 ? 'blue' : 'red'}>{t('ClienteEditInputTitulomin')} {(item.ativo as any) == 1 ? t('SistemaAtiva') : t('SistemaInativa')}</Badge>
      </>,
    },
    {
      label: '',
      wrapped: false,
      render: (item) => <div className="w-full flex gap-1">
        <Badge colorScheme="purple">
          {t('ClienteUnidadeArmazenamentos')} ({(item.silos ?? []).length})
        </Badge>
        <Badge colorScheme="linkedin">
          {t('ClienteUnidadeResponsaveis')} ({(item.responsaveis ?? []).length})
        </Badge>
      </div>,
    },
    {
      label: '',
      wrapped: false,
      render: (item) => <>{item?.cliente_id && <ClientUnitBaitMap clientUnit={item} />} </>,
      notClicable: true,
    },
    {
      label: '',
      wrapped: false,
      render: (item) => <>{item?.cliente_id && <ClientUnitDocumentsPermission clientUnit={item} />} </>,
      notClicable: true,
    },
    {
      label: t('SistemaAções'),
      wrapped: true,
      render: (item: any, index: any) => <>
        {eDesenvolvedor && <Button size={"sm"} colorScheme="red" onClick={() => onDeleteItem(index)}><FiTrash /></Button>}
      </>,
      notClicable: true,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [currentItem, setCurrentItem] = useState<ClienteUnidade | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure()

  const onOpenModal = (index: string | number) => {
    if (index === 'novo') {
      setCurrentIndex(-1)
      setCurrentItem(novoClienteUnidade())
      onOpen()
      return;
    }

    setCurrentIndex(index as number);
    setCurrentItem(value[index]);
    onOpen()
  }

  const onSaveModal = (form: ClienteUnidade) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    form = {
      ...form,
      changed: true,
    }

    if (currentIndex === -1) {
      const valuesToPush = [
        ...values,
        ...valuesDeleted,
      ]
      valuesToPush.push(form);
      setValue(valuesToPush)
      onClose();
      setCurrentItem(null);
      formik.submitForm()
      return;
    }

    values[currentIndex] = {
      ...values[currentIndex],
      ...form,
    };
    setValue([
      ...values,
      ...valuesDeleted,
    ])
    onClose();
    setCurrentItem(null);
    setCurrentIndex(-1);
    formik.submitForm()
  }

  const onDeleteItem = (index: number) => {
    const values = JSON.parse(JSON.stringify(value)).filter((i: any) => !i?.deleted);
    const valuesDeleted = JSON.parse(JSON.stringify(value)).filter((i: any) => i?.deleted);

    if (index === -1) return;

    if (!values[index]?.id) {
      values.splice(index, 1);
      setValue([
        ...values,
        ...valuesDeleted,
      ])
      return;
    }

    values[index] = {
      ...values[index],
      deleted: true,
      changed: true,
    };

    setValue([
      ...values,
      ...valuesDeleted,
    ])
  }

  interface IReceitaWs {
    nome: string;
    logradouro: string;
    numero: string;
    cep: string;
  }

  const [dadosCnpj, setDadosCnpj] = useState<IReceitaWs | null>(null);
  const [buscandoCnpj, setBuscandoCnpj] = useState(false);

  const consultaCnpj = async (cnpj: string) => {
    cnpj = (cnpj ?? '').replaceAll('.', '').replaceAll('-', '').replaceAll(' ', '').replaceAll('/', '').replaceAll('\\', '');

    if (cnpj.length < 14) return;

    try {
      setBuscandoCnpj(true);
      const { data } = await apiCall({ url: `consulta-cnpj/${cnpj}`, method: 'GET' });

      setBuscandoCnpj(false);
      if (!data?.nome) return;

      setDadosCnpj(data);
    } catch (err) {
      setBuscandoCnpj(false);
      mensagemErro(formatError(err));
      console.log(err)
    }

  }

  const CnpjComponent = () => {
    const formik = useFormikContext();

    useEffect(() => {
      if (!dadosCnpj) {
        return;
      }

      const cepString = dadosCnpj.cep.replaceAll('.', '').replaceAll('-', '').replaceAll(' ', '');

      formik.setValues({
        ...formik.values as any,
        nome_referencia: dadosCnpj.nome,
        endereco: dadosCnpj.logradouro,
        numero: dadosCnpj.numero,
        cep: cepString,
      })

      consultaCep(dadosCnpj.cep)

      setDadosCnpj(null);
    }, [formik]);

    return (
      <></>
    );
  }

  interface DadosCep {
    bairro: string;
    complemento: string;
    ibge: string;
    localidade: string;
    uf: string;
  }


  const [dadosCep, setDadosCep] = useState<DadosCep | null>(null);

  const consultaCep = async (cep: string) => {
    cep = (cep ?? '').replaceAll('.', '').replaceAll('-', '').replaceAll(' ', '');

    if (cep.length < 8) return;

    const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

    if (!data?.localidade) return;

    setDadosCep(data);
  }

  const CepComponent = () => {
    const formik = useFormikContext();

    useEffect(() => {
      if (!dadosCep) {
        return;
      }

      formik.setValues({
        ...formik.values as any,
        cidade: dadosCep.localidade,
        uf: dadosCep.uf,
      })

      setDadosCep(null);
    }, [formik]);

    return (
      <></>
    );
  };

  const onClickSalvar = () => {
    document.getElementById('botao-salvar-unidade')?.click()
  }

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  return (
    <>
      <ListHeaderSecondary label={t('ClienteEditInputTitulo')}>
        <DefaultButton size="sm" ml={4} onClick={() => onOpenModal('novo')}> {t('SistemaNova')} {t('ClienteEditInputTitulomin')} </DefaultButton>
      </ListHeaderSecondary>
      {value && <ResponsiveTable onClick={(item: any) => onOpenModal(item.index)} headers={headers} data={value.filter((i: any) => !i?.deleted)} />}

      <Modal scrollBehavior="inside" closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size={"5xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ListHeaderSecondary label={currentItem?.id ? t('SistemaEditar') + ' ' + t('ClienteEditInputTitulomin') : t('SistemaNova') + ' ' + t('ClienteEditInputTitulomin')} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {currentItem && <Formik
              enableReinitialize
              initialValues={currentItem}
              onSubmit={(val, { setErrors }) => {
                const validation = validateForm({ nome_referencia: 'required' }, val, t)
                if (Object.keys(validation).length > 0) {
                  setErrors(validation)
                  return;
                }

                onSaveModal({
                  ...val,
                  changed: true,
                });
              }}
            >
              {({ values }) => (
                <Form>
                  <Flex width="full" wrap="wrap">
                    {buscandoCnpj && <Text width={"full"}>Bucando dados do CNPJ...</Text>}
                    <InputField label={t('ClienteEditInputNome')} name="nome_referencia" width={"50%"} />
                    <InputField onBlur={() => consultaCnpj(values.cpf_cnpj)} label={t('ClienteEditInputCPFCNPJ')} name="cpf_cnpj" width={"50%"} />
                    <InputField onBlur={() => consultaCep(values.cep)} label={t('ClienteEditInputCEP')} name="cep" width={"40%"} />
                    <InputField label={t('ClienteEditInputCidade')} name="cidade" width={"40%"} />
                    <InputField label={t('ClienteEditInputUF')} name="uf" width={"20%"} />
                    <InputField label={t('ClienteEditInputEndereço')} name="endereco" width={"76%"} />
                    <InputField label={t('ClienteEditInputNúmero')} name="numero" width={"24%"} />
                    <InputField label={t('ClienteEditInputLatitude')} name="latitude" width={isMobile ? '50%' : '20%'} />
                    <InputField label={t('ClienteEditInputLongitude')} name="longitude" width={isMobile ? '50%' : '20%'} />
                    <InputGeolocalizacao mt={isMobile ? '0' : '8'} />
                    <Flex width={"full"} />
                    <InputCheckInteiro label={t('ClienteEditInputUnidadeAtiva')} name="ativo" />
                    <InputImage name="imagem" label={t('ClienteEditInputFotoUnidade')} />
                    <Flex width={"full"} />
                    <InputImage name="imagem_logo" label={t('ClienteEditInputFotoLogoUnidade')} />
                    <Flex width={"full"} />

                    <CepComponent />
                    <CnpjComponent />
                    <ClienteUnidadesResponsaveis />
                    <ClienteUnidadesSilos />

                    <DefaultButton id="botao-salvar-unidade" type="submit" mt={2}>{t('SistemaSalvar')} {t('ClienteEditInputTitulomin')}</DefaultButton>
                    <Button onClick={onClose} ml={2} mt={2} colorScheme="blackAlpha">{t('SistemaFechar')}</Button>
                  </Flex>
                </Form>)}
            </Formik>}
          </ModalBody>
          <ModalFooter>
            <DefaultButton onClick={onClickSalvar} mt={2}>{t('SistemaSalvar')} {t('ClienteEditInputTitulomin')}</DefaultButton>
            <Button onClick={onClose} ml={2} mt={2} colorScheme="blackAlpha">{t('SistemaFechar')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}